const SuccessIconWhite = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="white"
      />
      <path
        d="M4.35498 7.98717L6.72105 10.0011L11.3784 5.87109"
        stroke="#24AC21"
        stroke-width="1.3"
        stroke-linecap="square"
      />
    </svg>
  );
};

export default SuccessIconWhite;
