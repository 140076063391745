import styled from 'styled-components';

export const CartErrorContainer = styled.div`
  display: flex;
  ${props =>
    props.customStyles.mb && `margin-bottom: ${props.customStyles.mb}`}px;
  ${props => props.customStyles.mt && `margin-top: ${props.customStyles.mt}`}px;
  ${props =>
    props.customStyles.mr && `margin-right: ${props.customStyles.mr}`}px;
  ${props =>
    props.customStyles.ml && `margin-left: ${props.customStyles.ml}`}px;
  ${props =>
    props.customStyles.width && `max-width: ${props.customStyles.width}`};

  @media screen and (max-width: 767px) {
    ${props =>
      props.customStyles.mobileMb &&
      `margin-bottom : ${props.customStyles.mobileMb}`};
    margin-top: 0px;
    margin-bottom: 12px;
  }
`;
export const IconContainer = styled.div`
  display: flex;
  ${props =>
    props.customStyles.mbi && `margin-bottom: ${props.customStyles.mbi}`}px;
  ${props =>
    props.customStyles.mti && `margin-top: ${props.customStyles.mti}`}px;
  ${props =>
    props.customStyles.mri && `margin-right: ${props.customStyles.mri}`}px;
  ${props =>
    props.customStyles.mli && `margin-left: ${props.customStyles.mli}`}px;
  @media screen and (max-width: 767px) {
    ${props =>
      props.customStyles.transXi &&
      `transform : translateX(${props.customStyles.transXi})`};
  }
`;

export const ErrorText = styled.div`
  display: flex;
  color: #fc3d3d;
  font-size: ${props => props.fontSize || 14}px;
  @media screen and (max-width: 767px) {
    ${props => props.transX && `transform : translateX(${props.transX})`};
  }
`;
