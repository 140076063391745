import { CURRENCY_SYMBOL } from 'src/config';
import { AmountRow, GstIncludesContainer, TotalText } from '../../cart.styles';
import { isStoreGstIncluded } from 'src/utils/storeGSTStatus';

const GrandTotal = ({ cart }) => {
  function _renderGstInclusivePrice() {
    return isStoreGstIncluded() ? (
      <GstIncludesContainer>
        {`(Incl. ₹ ${cart?.total_gst_paid} GST)`}
      </GstIncludesContainer>
    ) : null;
  }
  return (
    <AmountRow className="tw-mb-[16px] tw-w-full md:tw-mb-0">
      <TotalText fontWeight={600} className="!tw-text-[16px]/[20px]">
        Grand Total{_renderGstInclusivePrice()}
      </TotalText>
      <TotalText
        fontWeight={600}
        className="!tw-text-[18px]/[22px] md:!tw-text-[16px]/[20px]"
      >
        {cart?.buyer_pay_value &&
          `${CURRENCY_SYMBOL} ${cart?.buyer_pay_value?.toFixed(2)}`}
      </TotalText>
    </AmountRow>
  );
};

export default GrandTotal;
