import useSetCart from 'src/utils/customHooks';
import CartPromoStrip from '../../CartPromoStrip';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { StripContainer, StripCTA, StripIconInfoWrapper } from '../../Common';

const OfferAppliedView = ({
  cartData,
  isBankOfferApplied,
  bankOfferDataFromCart,
  storeOfferDataFromCart,
}) => {
  const { triggerSetCart } = useSetCart();

  const removePaymentCoupon = () => {
    const tempPayload = {
      ...cartData,
      payment_offer: {},
      store_offer: {
        ...cartData.store_offer,
        promo_code: '',
        promo_id: 0,
        promo_discount: 0,
      },
    };
    triggerSetCart(tempPayload);
  };

  function getAppliedOffer() {
    return (
      isBankOfferApplied
        ? bankOfferDataFromCart?.promo_code
        : storeOfferDataFromCart?.promo_code
    )?.toUpperCase();
  }

  function getAppliedOfferDiscount() {
    return isBankOfferApplied
      ? bankOfferDataFromCart?.promo_discount
      : storeOfferDataFromCart?.promo_discount;
  }

  return (
    <div className="tw-flex tw-flex-col tw-gap-[18px] tw-px-[12px] tw-py-[16px] md:tw-gap-[16px] md:tw-p-[24px]">
      {IS_DESKTOP ? (
        <CartPromoStrip
          primaryText={getAppliedOffer()}
          secondaryText="Offer Applied"
          onApplyOrRemoveClick={removePaymentCoupon}
          savingAmount={getAppliedOfferDiscount()}
          primaryButtonText="Remove"
          primaryButtonClasses="!tw-pr-0"
          isOfferApplied={true}
        />
      ) : (
        <StripContainer
          customClasses={`!tw-flex-row tw-justify-between tw-items-center tw-border-none tw-px-[6px]`}
        >
          <StripIconInfoWrapper>
            <div className="tw-flex tw-flex-col tw-gap-[8px]">
              <p className={`tw-m-0 tw-text-[16px]/[20px] tw-font-semibold`}>
                Offer Applied
              </p>
              <p
                className={`tw-m-0 tw-flex tw-flex-col tw-gap-[8px] tw-text-[14px]/[20px] tw-font-medium tw-text-[#0D9738]`}
              >
                <span className="tw-w-fit tw-bg-[#E2FFEB] tw-px-[4px] tw-py-[1px]">
                  {getAppliedOffer()}
                </span>
                {!!getAppliedOfferDiscount() && (
                  <span className="tw-text-[14px]/[20px] tw-font-semibold  md:tw-text-[14px]/[20px]">
                    Save ₹{getAppliedOfferDiscount()}
                  </span>
                )}
              </p>
            </div>
          </StripIconInfoWrapper>
          <StripCTA ctaTextOrIcon={'Remove'} onClick={removePaymentCoupon} />
        </StripContainer>
      )}
    </div>
  );
};

export default OfferAppliedView;
