import store from '../redux/store';
import getItemDetailFromCart from './getItemDetailFromCart';

// this function is being used in CheckItemDelivery
export function getItemQuantityNameId(item_id = 0, item_name = '') {
  if (item_id) {
    // assume quantity 1 if item is not added to cart
    const { quantity = 1 } = getItemDetailFromCart(item_id);
    const item_details = [
      {
        item_id: item_id,
        item_name: item_name,
        quantity: quantity,
      },
    ];
    return item_details;
  }
  const item_details = [];
  const itemsArray = store.getState().cartReducer.items;
  for (let i = 0; i < itemsArray?.length; i++) {
    item_details.push({
      item_id: itemsArray[i].item_id,
      item_name: itemsArray[i].item_name,
      quantity: itemsArray[i].quantity,
    });
  }
  return item_details;
}
