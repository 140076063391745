/**
 * Delivery Address List page
 */
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { saveSelectedAddress, setCart } from '../../redux/actions';
import { addDeliveryInfoInCart } from '@/utils/addDeliveryInfoInCart';
import { isDeliveryPincodeBased } from '@/utils/isDeliveryPincodeBased';
import { setUserSavedAddress } from '@/utils/setUserSavedAddress';
import { getAddressByServiceabilityUnserviceabilty } from '@/utils/getAddressByServiceabilityUnserviceabilty';
import { triggerGetItemDeliveryCharges } from '@/utils/triggerGetItemDeliveryChages';
import { ListHeading } from './DeliveryList.styles';
import { useSSRSelector } from '@/redux/ssrStore';
import UserAddressCard from 'src/components/UserAddressCard';
import BasicModal from 'src/components/BasicModal';
import { Button } from '@/components/DesignSystem/AtomicComponents';
import { getCustomFieldPayload } from 'src/utils/getStringifyAddress';

const ADDRESS_TYPE = {
  SERVICABLE: 'SERVICABLE ADDRESSES',
  UNSERVICABLE: 'NON-SERVICABLE ADDRESSES',
  PINCODE_REQUIRED: 'PINCODE REQUIRED',
  SAVED_ADDRESS: 'SAVED ADDRESSES',
};

const DeliveryList = (props) => {
  const dispatch = useDispatch();
  const storeInfo = useSSRSelector((state) => state.storeReducer.store);
  const userData = useSelector((state) => state.userReducer);
  const cartData = useSelector((state) => state.cartReducer);
  const { serviceable_address_ids, pincode_required, unserviceable_address_ids } =
    getAddressByServiceabilityUnserviceabilty();

  useEffect(() => {
    if (props.isVisible) {
      dispatch(saveSelectedAddress({}));
    }
  }, []);

  const setAddressValue = (selectedAddFromUsersAddList, addressType) => {
    if (addressType === ADDRESS_TYPE.UNSERVICABLE) {
      return;
    }
    if (
      selectedAddFromUsersAddList?.missing_fields[0] &&
      selectedAddFromUsersAddList?.missing_fields[0]?.length > 0
    ) {
      dispatch(saveSelectedAddress(setUserSavedAddress(selectedAddFromUsersAddList)));
      props.setEditId(selectedAddFromUsersAddList.address_id);
      props.toggleAddDeliveryForm(true);
      props.toggleModal();
    } else {
      dispatch(saveSelectedAddress(setUserSavedAddress(selectedAddFromUsersAddList)));
      const cartPayload = addDeliveryInfoInCart(cartData, {
        ...selectedAddFromUsersAddList,
        user_name: selectedAddFromUsersAddList?.user_name?.text || '',
        landmark: selectedAddFromUsersAddList?.landmark?.text || '',
        pincode: selectedAddFromUsersAddList?.pincode?.text || '',
        city: selectedAddFromUsersAddList?.city?.text || '',
        state: selectedAddFromUsersAddList?.state?.text || '',
        country: selectedAddFromUsersAddList?.country?.text || '',
        address1: selectedAddFromUsersAddList?.address1?.text || '',
        address2: selectedAddFromUsersAddList?.address2?.text || '',
        address_id: selectedAddFromUsersAddList?.address_id,
        latitude: selectedAddFromUsersAddList?.latitude || 0,
        longitude: selectedAddFromUsersAddList?.longitude || 0,
        alternate_phone: selectedAddFromUsersAddList?.alternate_phone?.text || '',
        email_id: selectedAddFromUsersAddList?.email_id?.text || '',
        age: selectedAddFromUsersAddList?.age?.text || '',
        gender: selectedAddFromUsersAddList?.gender?.text || '',
        custom_field_data_list:
          getCustomFieldPayload(selectedAddFromUsersAddList?.custom_field_data_list) ||
          [],
      });
      dispatch(setCart(cartPayload));
      if (isDeliveryPincodeBased()) triggerGetItemDeliveryCharges();
      props.toggleModal();
      props.toggleAddDeliveryForm(false);
    }
  };

  const openAddressAdditionForm = () => {
    props.toggleAddDeliveryForm(true);
    props.toggleModal();
  };

  const onAddNewAddressBtnHandle = () => {
    openAddressAdditionForm();
    props.setEditId(0);
  };

  const onEditClick = (id) => {
    openAddressAdditionForm();
    props.setEditId(id);
  };

  function renderAddressDetails(addressDetails, addressType) {
    const isUnserviceable = addressType === ADDRESS_TYPE.UNSERVICABLE;
    const isIncomplete = addressType === ADDRESS_TYPE.PINCODE_REQUIRED;

    return (
      <div
        className={
          (isUnserviceable ? 'o-40' : '') +
          ' tw-mb-[4px] tw-flex tw-flex-col tw-gap-[16px]'
        }
      >
        {userData?.savedAddress &&
          addressDetails?.map((item, index) => {
            return (
              <UserAddressCard
                key={index}
                onEditClick={
                  !isUnserviceable ? () => onEditClick(item?.address_id || 0) : null
                }
                onDeliverClick={
                  !isUnserviceable && !isIncomplete
                    ? () => setAddressValue(item, addressType)
                    : null
                }
                isIncomplete={isIncomplete}
                isUnserviceable={isUnserviceable}
                userName={item?.user_name?.text}
                addressType={item?.address_type}
                addressName={item?.address_name}
                alternatePhone={item?.alternate_phone?.text}
                address1={item?.address1?.text}
                address2={item?.address2?.text}
                city={item?.city?.text}
                state={item?.state?.text}
                pincode={item?.pincode?.text}
                customClasses="md:!tw-border-solid md:!tw-px-[24px] md:!tw-py-[14px] md:!tw-rounded"
              />
            );
          })}
      </div>
    );
  }

  function renderListHeading(headingText) {
    return (
      <div className="tw-mb-[12px] tw-flex tw-flex-col-reverse tw-justify-between tw-gap-[20px] md:tw-flex-row">
        <ListHeading>{headingText}</ListHeading>
        <Button
          textTransform="capitalize"
          roundness={storeInfo?.theme?.roundness}
          className="!tw-w-full tw-py-[15px] !tw-text-sm !tw-font-semibold md:!tw-w-fit md:!tw-border-none md:tw-p-0"
          label="Add New Address"
          fontColor={storeInfo?.theme?.colors?.primary_color}
          variant="outline"
          onClick={onAddNewAddressBtnHandle}
        />
      </div>
    );
  }

  function pincodeBasedDeliveryList() {
    return (
      <>
        {serviceable_address_ids?.length > 0 &&
          renderListHeading(ADDRESS_TYPE.SERVICABLE)}
        {renderAddressDetails(serviceable_address_ids, ADDRESS_TYPE.SERVICABLE)}
        {renderAddressDetails(pincode_required, ADDRESS_TYPE.PINCODE_REQUIRED)}
        {renderAddressDetails(unserviceable_address_ids, ADDRESS_TYPE.UNSERVICABLE)}
      </>
    );
  }

  function userAddressList() {
    return (
      <div className="tw-flex tw-flex-col">
        {userData?.addressList?.length > 0 &&
          renderListHeading(ADDRESS_TYPE.SAVED_ADDRESS)}
        {renderAddressDetails(userData?.addressList, ADDRESS_TYPE.SAVED_ADDRESS)}
      </div>
    );
  }

  return (
    <BasicModal
      show={props.isVisible}
      onClose={props.toggleModal}
      heading="Select Delivery Address"
      showCloseIcon={true}
      customClasses="!tw-max-h-[80%] md:!tw-max-h-[60%] !tw-font-inter"
    >
      <div className="flex justify-center tw-flex-col tw-gap-[16px] tw-px-[16px] tw-py-[20px] md:tw-px-[24px] md:tw-py-[16px]">
        {isDeliveryPincodeBased() ? pincodeBasedDeliveryList() : userAddressList()}
      </div>
    </BasicModal>
  );
};

export default DeliveryList;
