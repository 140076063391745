import { deviceWidth } from '@/utils/deviceWidth';
import { DropDownArrow } from '@/assets/svgExports/DropDownArrow';
import React from 'react';
import { useOnScrollDownCta } from 'src/hooks/useOnScrollDownCta';
import {
  ScrollButton,
  ScrollContainer,
  ScrollIcon,
  ScrollTextIconContainer,
} from './Scroll.styles';

/**
 * Scroll Tab Provides User With Information That
 * current List of Items is Scrollable
 * removes after list is scrolled to the end.
 * @param {*} referenceToCheckScrollHeight
 * @returns
 */

function Scroll(referenceToCheckScrollHeight) {
  const showScrollDownHelper = useOnScrollDownCta(referenceToCheckScrollHeight);

  if (deviceWidth < 767) return null;
  return (
    <>
      {showScrollDownHelper && (
        <ScrollContainer>
          <ScrollButton>
            <ScrollTextIconContainer>
              Scroll
              <ScrollIcon>
                <DropDownArrow fillColor={'#ffffff'} width={9} height={5} />
              </ScrollIcon>
            </ScrollTextIconContainer>
          </ScrollButton>
        </ScrollContainer>
      )}
    </>
  );
}

export default Scroll;
