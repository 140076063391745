import { useEffect, useState } from 'react';

/**
 * this useEffect returns a variable that toggles
 * when we scroll to the bottom and top
 * @param {*} referenceToCheckScrollHeight
 * @returns
 */

export default function useOnScrollRemoveShadow(referenceToCheckScrollHeight) {
  const [showScrollDownShadow, setShowScrollDownShadow] = useState(true);

  useEffect(() => {
    referenceToCheckScrollHeight?.current?.addEventListener(
      'scroll',
      checkIfScrolledDownTop
    );
    checkIfScrolledDownTop();
    return () => {
      removeScrollEventListener();
    };
  }, []);

  function removeScrollEventListener() {
    referenceToCheckScrollHeight?.current?.removeEventListener(
      'scroll',
      checkIfScrolledDownTop
    );
  }

  function checkIfScrolledDownTop() {
    if (
      referenceToCheckScrollHeight?.current?.offsetHeight +
        referenceToCheckScrollHeight?.current?.scrollTop >=
      referenceToCheckScrollHeight?.current?.scrollHeight
    ) {
      setShowScrollDownShadow(false);
    } else setShowScrollDownShadow(true);
  }
  return showScrollDownShadow;
}
