import { getPaymentOffersList } from '@/redux/actions';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AllCartPagePromos from 'src/components/CouponsAndVouchers/AllCartPagePromos';

function CheckoutInfoTabPromo(props) {
  const { storeId } = useSelector((state) => ({
    storeId: state.storeReducer.store?.store_id,
  }));
  const dispatch = useDispatch();

  useEffect(() => {
    if (storeId) {
      dispatch(getPaymentOffersList(storeId));
    }
  }, [storeId]);

  return <AllCartPagePromos />;
}

export default CheckoutInfoTabPromo;
