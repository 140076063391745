import { InfoOutlined } from '@mui/icons-material';
import { capitalize } from 'lodash';
import { HomeDarkIcon } from 'src/assets/svgExports/HomeDarkIcon';
import { OtherDarkIcon } from 'src/assets/svgExports/OtherDarkIcon';
import { WorkDarkIcon } from 'src/assets/svgExports/WorkDarkIcon';
import { getThemeColor } from 'src/utils/getThemeColor';

export const renderAddressTypeIcon = (type) => {
  switch (type) {
    case 'home':
      return <HomeDarkIcon />;
    case 'work':
      return <WorkDarkIcon />;
    case 'others':
      return <OtherDarkIcon />;
  }
};

const UserAddressCard = ({
  onEditClick = null,
  onDeliverClick = null,
  estimatedDeliveryTime = null,
  customClasses = '',
  isSelected = false,
  userName = '',
  addressType = 'home',
  addressName = '',
  alternatePhone = '',
  address1 = '',
  address2 = '',
  city = '',
  state = '',
  pincode = '',
  isIncomplete = false,
  isUnserviceable = false,
}) => {
  const buttonsData = [
    {
      text: isIncomplete
        ? 'Add missing details'
        : onDeliverClick
          ? 'Edit'
          : 'Edit Address',
      onClick: onEditClick,
      show: !!onEditClick,
    },
    {
      text: 'Deliver Here',
      onClick: onDeliverClick,
      show: !isSelected && !!onDeliverClick,
    },
  ];

  function getTrimmedString(str) {
    return str.length > 6 ? str.slice(0, 6) + '...' : str;
  }

  return (
    <div
      className={
        'tw- tw-flex tw-flex-col tw-gap-[12px] tw-rounded-lg tw-border tw-border-solid tw-border-[#E2E2E2] tw-pt-[12px] md:tw-border-none md:tw-p-0 ' +
        customClasses
      }
    >
      <div className="tw-flex tw-flex-col tw-gap-[4px] tw-px-[16px] md:tw-p-0">
        <div className="tw-flex tw-justify-between">
          <p className="tw-m-0 tw-text-sm">{userName}</p>
          <span className="tw-flex tw-items-center tw-gap-[4px] tw-text-xs">
            {renderAddressTypeIcon(addressType)}
            {getTrimmedString(capitalize(addressName || addressType))}
          </span>
        </div>
        <p className="tw-m-0 tw-text-sm  tw-text-[#7D7D7D]">{alternatePhone}</p>
        <p className="tw-m-0 tw-text-sm  tw-text-[#7D7D7D]">
          {[address1, address2, city, state, pincode].filter((data) => !!data).join(', ')}
        </p>
        {isIncomplete && (
          <p className="tw-m-0 tw-flex tw-items-center tw-text-sm tw-text-[#E2574C] ">
            <InfoOutlined fontSize="small" /> Incomplete address
          </p>
        )}
      </div>
      <div className="tw-flex tw-flex-col-reverse tw-justify-between tw-gap-[12px] md:tw-flex-row">
        <div className="tw-flex tw-divide-x tw-border-t tw-border-solid tw-border-[#E2E2E2] md:tw-flex-row-reverse md:tw-gap-[16px] md:tw-border-none">
          {buttonsData.map(
            (data, index) =>
              data.show && (
                <span
                  key={index}
                  className="tw-flex tw-flex-1 tw-cursor-pointer tw-justify-center tw-border-r tw-border-solid tw-border-[#E2E2E2] tw-px-[16px] tw-py-[12px] tw-text-sm tw-font-semibold last:tw-border-none md:tw-flex-none md:tw-border-none md:tw-p-0"
                  style={{ color: getThemeColor() }}
                  onClick={data.onClick}
                >
                  {data.text}
                </span>
              )
          )}
        </div>
        {!!estimatedDeliveryTime && (
          <p className="tw-m-0 tw-px-[16px] tw-text-xs tw-italic md:tw-p-0">
            {estimatedDeliveryTime}
          </p>
        )}
      </div>
    </div>
  );
};

export default UserAddressCard;
