export const SimpleCloseIcon = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8">
      <path
        id="Icon_material-close"
        data-name="Icon material-close"
        d="M15.5,8.306,14.694,7.5,11.5,10.694,8.306,7.5,7.5,8.306,10.694,11.5,7.5,14.694l.806.806L11.5,12.306,14.694,15.5l.806-.806L12.306,11.5Z"
        transform="translate(-7.5 -7.5)"
        fill="#fc3d3d"
      />
    </svg>
  );
};
