import store from '../redux/store';
import { GST_FLAG } from './constants';

export function isStoreGstIncluded() {
  const storeData = store.getState().storeReducer.store;
  if (
    storeData.services?.gst_flag === GST_FLAG.ENABLE &&
    storeData?.gst_info?.is_gst_included
  )
    return true;
  return false;
}

export function isStoreGstExcluded() {
  const storeData = store.getState().storeReducer.store;
  if (
    storeData.services?.gst_flag === GST_FLAG.ENABLE &&
    !storeData?.gst_info?.is_gst_included
  )
    return true;
  return false;
}
