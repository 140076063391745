import styled from 'styled-components';

export const CartRemoveCtaContainer = styled.div`
  display: flex;
  cursor: pointer;
  margin-left: ${props => props.customStyles.ml}px;
  margin-right: ${props => props.customStyles.mr}px;
  margin-top: ${props => props.customStyles.mt}px;
  margin-bottom: ${props => props.customStyles.mb}px;
`;

export const CartRemoveIcon = styled.div`
  display: flex;
  margin-left: ${props => props.mli || 0}px;
  margin-right: ${props => props.mri || 0}px;
  margin-top: ${props => props.mti || 0}px;
  margin-bottom: ${props => props.mbi || 0}px;
`;

export const CartRemoveText = styled.div`
  display: flex;
  font-size: ${props => props.fontSize || 12}px;
  color: #fc3d3d;
`;
