import styled from 'styled-components';

export const Item = styled.section`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  :last-child {
    border-bottom: none;
  }
`;

export const ItemInfo = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: nowrap;
`;

export const ItemImage = styled.div`
  object-fit: cover;
  margin-right: 10px;
  border: 0.5px solid #d9d9d9;
  padding: 2px;
  border-radius: 8px;

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  @media screen and (min-width: 767px) {
    width: 64px;
    height: 64px;
  }

  @media screen and (max-width: 766px) {
    width: 67px;
    height: 67px;
  }
`;

export const ItemDetails = styled.div`
  padding-right: 10px;
  display: flex;
  flex: 1;
  flex-direction: column;
  height: fit-content;
  min-width: 12vw;
  @media screen and (max-width: 767px) {
    min-width: 38vw;
  }
`;

export const ItemName = styled.p`
  margin: 0;
  min-height: 15px;
  height: fit-content;
  margin-bottom: 7px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #000000;

  @media screen and (min-width: 767px) {
    font-size: 14px;
  }

  @media screen and (max-width: 766px) {
    font-size: 13px;
  }
`;

export const ItemVariantName = styled.div`
  font-size: 12px;
  font-weight: 300;
  padding-bottom: 6px;
`;

export const ItemSub = styled.div`
  display: flex;

  @media screen and (min-width: 767px) {
    flex-direction: row;
  }

  @media screen and (max-width: 766px) {
    flex-direction: column;
  }
`;

export const ItemPrice = styled.div`
  display: flex;
  flex: 0;
  margin-bottom: 4.5px;
`;

export const AmountText = styled.p`
  display: flex;
  flex-flow: row wrap;
  flex: 0;
  width: fit-content;
  font-size: 12px;
  font-weight: 400;
  margin: 0;
  margin-right: 10px;
`;

export const ItemRemarksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;

  & > p {
    font-size: 12px;
    margin: 0 0 2px 0;
    word-break: break-all;

    &.heading {
      color: #808080;
    }

    &:not(.heading) {
      cursor: pointer;
    }
  }

  @media screen and (min-width: 767px) {
    max-width: 380px;

    & > p {
      margin: 0 0 4px 0;
    }
  }
`;

export const DiscountedPrice = styled(AmountText)`
  color: #000000;
`;

export const ActualPrice = styled(AmountText)`
  color: rgba(112, 112, 112, 0.43);
  text-decoration: line-through;
`;

export const ItemDiscount = styled.p`
  font-size: 12px;
  text-transform: uppercase;
  color: #0dbf44;
  margin: 0;
  @media screen and (min-width: 767px) {
    width: 5vw;
  }
`;

export const ItemTotalValue = styled.div`
  font-size: 14px;
  text-align: right;
  height: fit-content;
`;
export const CartButtonItemValueContainer = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  align-items: center;
  justify-content: space-between;
`;

export const DeliveryCharges = styled.div`
  display: flex;
  color: #7e7e7e;
  font-size: 12px;
  margin-top: 4px;
`;

export const ItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
`;
