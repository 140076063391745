import { useSelector } from 'react-redux';
import CustomRadio from '../../../CustomRadio';
import {
  MINIMUM_ORDER_AMOUNT,
  PARTIAL_PAYMENT_OPTION,
} from '../../../../utils/constants';
import ReactTooltip from 'react-tooltip';
import TotalAmountSaved from '../TotalAmountSaved';
import { useSSRSelector } from '@/redux/ssrStore';

const SelectPartialOrFullPayment = ({ selectedType, setSelectedType }) => {
  const { partial_payment_amount, orderDetails, paymentModalOptionsVisibility } =
    useSelector((state) => ({
      partial_payment_amount: state.paymentReducer.partial_payment_amount,
      orderDetails: state.orderReducer.orderDetails,
      paymentModalOptionsVisibility: state.commonReducer.paymentModalOptionsVisibility,
    }));

  const { storeInfo } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
  }));

  const isPartialPaymentToggleNotAllowed =
    partial_payment_amount < MINIMUM_ORDER_AMOUNT ||
    partial_payment_amount >= orderDetails.buyer_pay_value;

  const isFullPayment = selectedType === PARTIAL_PAYMENT_OPTION.FULL_PAYMENT;

  return (
    <>
      <div className="partial-payment-section">
        <h2 className="title">Select a payment method</h2>
        <div className="radio-input-wrapper">
          <div className="radio-input-item">
            <CustomRadio
              type="radio"
              checked={isFullPayment}
              aria-checked={isFullPayment}
              onClick={() => setSelectedType(PARTIAL_PAYMENT_OPTION.FULL_PAYMENT)}
            />
            <div className="radio-content-wrap">
              <article className="fw5">Full Payment</article>
            </div>
          </div>
          <div className="radio-input-item">
            <CustomRadio
              type="radio"
              checked={!isFullPayment}
              aria-checked={!isFullPayment}
              className={
                isPartialPaymentToggleNotAllowed
                  ? 'tw-cursor-not-allowed'
                  : 'tw-cursor-pointer'
              }
              onClick={() => setSelectedType(PARTIAL_PAYMENT_OPTION.PARTIAL_PAYMENT)}
              disabled={isPartialPaymentToggleNotAllowed}
            />
            <div className="radio-content-wrap mobile-radio-wrap">
              <article className="">
                {storeInfo.services.partial_payment_flag ===
                PARTIAL_PAYMENT_OPTION.PARTIAL_PAYMENT
                  ? `${storeInfo.services.partial_payment_value}% `
                  : ''}
                Partial Payment - Rs. {partial_payment_amount}
              </article>
              <img
                loading="lazy"
                src="/assets/icons/tooltipGrayIcon.svg"
                data-tip
                data-for="partial-payment-info"
              />
              <ReactTooltip
                id="partial-payment-info"
                place="bottom"
                effect="solid"
                multiline={true}
                padding="8px"
                disable={false}
                arrowColor="#4F4F4F"
              >
                <p className="mv0 fw5 f12px">
                  Choose this option to make a partial payment. The remaining amount will
                  be collected at the time of delivery/pickup.
                </p>
              </ReactTooltip>
            </div>
          </div>
        </div>
      </div>
      <TotalAmountSaved amount={orderDetails?.amount_saved} />
      <div
        className={`f4 fw6 pv3 ${!paymentModalOptionsVisibility ? 'dn' : ''}`}
        style={{ paddingTop: '0px' }}
      >
        Pay ₹{orderDetails?.buyer_pay_value}
      </div>
    </>
  );
};

export default SelectPartialOrFullPayment;
