import DateIcon from '@/assets/svgExports/DateIcon';
import TimeIcon from '@/assets/svgExports/TimeIcon';
import { convertTo12HourFormat } from '../ProductScheduler/utils';

const ItemSchedulingInfo = ({
  schedulingInfo,
  customClasses = '',
  headingClasses = '',
  paraClasses = '',
}) => {
  const { date, time } = schedulingInfo || {};
  const infoList = [
    {
      icon: <DateIcon />,
      infoName: 'Date',
      infoData: date || '',
    },
    {
      icon: <TimeIcon />,
      infoName: 'Slot time',
      infoData:
        time?.from && time?.to
          ? `${convertTo12HourFormat(time?.from)} - ${convertTo12HourFormat(time?.to)}`
          : '',
    },
  ];

  return (
    !!date && (
      <div
        className={`tw-flex tw-flex-col tw-gap-[12px] tw-pb-[8px] tw-pt-[24px] ${customClasses}`}
      >
        <span
          className={`tw-flex tw-text-[14px]/[15px]  tw-font-normal ${headingClasses}`}
        >
          Booking details
        </span>
        {infoList.map(
          (info, index) =>
            !!info.infoData && (
              <div
                key={index}
                className={`tw-flex tw-items-center tw-gap-[8px] tw-text-[12px]/[15px] tw-font-normal  tw-text-[#4E4E4E] ${paraClasses}`}
              >
                {info.icon}
                <span className="tw-flex tw-items-center tw-gap-[4px]">
                  {info.infoName}:
                  <span className="tw-flex tw-items-center tw-font-normal tw-text-[#6B6B6B]">
                    {info.infoData}
                  </span>
                </span>
              </div>
            )
        )}
      </div>
    )
  );
};

export default ItemSchedulingInfo;
