const ModifiedCartNotificationBar = ({ cartValidationData, showValidationError }) => {
  return (
    cartValidationData?.is_cart_modified && (
      <article className="cart-update-notifier flex justify-center items-center bg-black w-100 white f7 fw3 pv2 ph3">
        <img src="/assets/images/cart-change-icon.png" className="img h1 pr2" />
        There are updates in your cart{' '}
        <span className="f7 fw3 bb b--dotted mh2" onClick={showValidationError}>
          View Details
        </span>
      </article>
    )
  );
};

export default ModifiedCartNotificationBar;
