/**
 * Cart Validation
 * Error message Component
 */

import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  saveCartValidationData,
  setCartValidationErrorMsgModalVisibility,
} from 'src/redux/actions';
import Modal from '../../../../components/Common/Modal';
import { roundnessCalculator } from '../../../../components/DesignSystem/AtomicComponents/util';
import { getThemeColor } from 'src/utils/getThemeColor';

const CartValidationErrorMsgModal = () => {
  const dispatch = useDispatch();
  const themeColor = getThemeColor();

  const { theme, cartValidationErrorMsgModal, cartValidationData } = useSelector(
    (state) => ({
      theme: state.storeReducer.store?.theme,
      store_info: state.storeReducer.store.store_info,
      cartValidationErrorMsgModal: state.errorReducer.cart.cartValidationErrorMsgModal,
      cartValidationData: state.errorReducer.cart.cartValidationData,
    })
  );

  useEffect(() => {
    if (cartValidationErrorMsgModal) {
      document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
    } else {
      document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    }
  }, [cartValidationErrorMsgModal]);

  const onClose = () => {
    dispatch(setCartValidationErrorMsgModalVisibility(false));
    dispatch(saveCartValidationData(null));
  };

  return (
    cartValidationErrorMsgModal && (
      <Modal
        visible={cartValidationErrorMsgModal}
        className="bottom-modal cartValidationErrorMsgModal"
        animation={'slideUp'}
        onClose={onClose}
      >
        <span className="closeicon" onClick={onClose}>
          <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
        </span>

        <div className="f4 fw6 pv3">Note</div>
        <section className="pt2 pb5 overflow-y-scroll h5">
          {cartValidationData?.message_list?.map((el, ind) => {
            return (
              <article className="flex pb3" key={`list-error-cartvalidation-${ind}`}>
                <div className="flex justify-center items-start">
                  <img src="/assets/images/information@2x.png" className="info-icon" />
                </div>
                <div className="f6 fw4 ph2 w-90">{el}</div>
              </article>
            );
          })}
        </section>
        <div className="f4 fw6 pa3 absolute bottom-0 left-0 w-100 bg-white">
          <button
            className="f5 fw6 pv3 w-100 br-pill bn white"
            style={{
              backgroundColor: themeColor || '#EA5151',
              borderRadius: theme ? `${roundnessCalculator(theme?.roundness)}` : '8px',
            }}
            onClick={onClose}
          >
            View Cart
          </button>
        </div>
      </Modal>
    )
  );
};

export default CartValidationErrorMsgModal;
