import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSSRSelector } from 'src/redux';
import { pushEventsLog, setPrepaidLimitDisablePayNow } from 'src/redux/actions';
import { RootState } from 'src/redux/reducers';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { getPaymentMethodUsedByCustomer } from 'src/utils/getPaymentMethodUsedByCustomer';

const useCartPageLandingEvent = () => {
  const dispatch = useDispatch();

  const { storeInfo } = useSSRSelector((state) => ({
    storeInfo: state.storeReducer.store,
  }));

  const { userData } = useSelector((state: RootState) => ({
    userData: state.userReducer,
  }));

  useEffect(() => {
    dispatch(setPrepaidLimitDisablePayNow(false));
    dispatch(
      pushEventsLog({
        event_name: 'Cx_Cart_Page',
        data: {
          store_id: storeInfo?.store_id,
          domain: storeInfo?.store_info?.domain,
          device: IS_DESKTOP ? 'Desktop' : 'Mobile',
          cx_number: userData?.data?.phone,
          payment_method: getPaymentMethodUsedByCustomer(
            storeInfo?.services?.payment_method
          ),
        },
      })
    );
  }, []);
};

export default useCartPageLandingEvent;
