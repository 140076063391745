import styled from 'styled-components';

export const CheckoutInfoTabContainer = styled.div`
  display: flex;
  min-height: ${(props) => (props.isPromoApplied ? '88' : '64')}px;
  border: ${(props) => (props.instructionPopup ? 'none' : '1px solid #e8e8eb')};
  border-radius: ${(props) => (props.instructionPopup ? '0px' : '8px')};
  margin-top: ${(props) => (props.instructionPopup ? '0px' : '16px')};
  justify-content: space-between;
  ${(props) =>
    props.toggleAddDeliveryForm &&
    `border-bottom-right-radius : 0px;
  border-bottom-left-radius : 0px`};
  @media screen and (max-width: 767px) {
    width: 100vw;
    border: 0px;
    border-radius: 0px;
    margin-top: 0px;
    min-height: ${(props) => (props.isPromoApplied ? '76' : '52')}px;
    border-top: 1px solid #e8e8eb;
  }
`;

export const CheckoutInfoTabLeftContainer = styled.div`
  display: flex;
`;

export const CheckoutInfoTabMiddleContainer = styled.div`
  display: flex;
  align-items: flex-start;
  margin-left: 8px;
  font-size: ${(props) => props.fontSize || '14'}px;
  flex-direction: column;
  justify-content: center;
  @media screen and (max-width: 767px) {
    font-size: 12px;
    margin-left: 16px;
    ${(props) => props.cartError && `margin-top : 20px`};
  }
`;

export const CheckoutInfoTabRightContainer = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const CheckoutInfoTabLeftIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-bottom: ${(props) => (props.isPromoApplied ? '10' : '0')}px;
`;
