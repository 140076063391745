import getCustomCtaText from 'src/utils/getCustomCtaText';
import {
  AmountContainer,
  AmountRow,
  DeliveryChargeDropDownContainer,
  DropDownArrowContainer,
  GstExcludesContainer,
  TotalText,
} from '../../cart.styles';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import {
  DELIVERY_CHARGE_TYPE,
  ORDER_TYPE,
  STORE_CUSTOMIZATION,
} from 'src/utils/constants';
import { CURRENCY_SYMBOL } from 'src/config';
import { isDeliveryPincodeBased } from 'src/utils/isDeliveryPincodeBased';
import { useState } from 'react';
import { DropDownArrow } from 'src/assets/svgExports/DropDownArrow';
import DeliveryChargeList from 'src/components/DeliveryChargeList/DeliveryChargeList';
import { getStoreType } from 'src/utils/getStoreType';
import { isStoreGstExcluded } from 'src/utils/storeGSTStatus';
import GrandTotal from './GrandTotal';

const OrderAmountInfo = ({ showGrandTotal = false }) => {
  const { cart, storeInfo } = useSelector((state: RootState) => ({
    storeInfo: state.storeReducer.store,
    cart: state.cartReducer,
  }));

  const [showDeliveryChargeList, setShowDeliveryChargeList] = useState(false);

  const checkDeliveryChargeTypeComparison = () => {
    if (
      storeInfo?.services?.delivery_charge_type === DELIVERY_CHARGE_TYPE.FREE ||
      storeInfo?.services?.delivery_charge_type === DELIVERY_CHARGE_TYPE.FIXED
    )
      return true;
    if (
      storeInfo?.services?.delivery_charge_type === DELIVERY_CHARGE_TYPE.NOT_VALID ||
      storeInfo?.services?.delivery_charge_type === DELIVERY_CHARGE_TYPE.CUSTOM
    ) {
      return storeInfo?.services?.free_delivery_above
        ? cart.items_total > storeInfo?.services?.free_delivery_above
        : false;
    }
    return true;
  };

  function handleDeliveryChargeList() {
    setShowDeliveryChargeList(!showDeliveryChargeList);
  }

  const _renderPromoDiscountBox = () => {
    return cart?.store_offer?.promo_code && cart?.store_offer?.promo_discount ? (
      <article className="flex justify-between items-center f6 lime-green-color">
        <span>Promo Discount</span>
        <span>(-) ₹{cart?.store_offer?.promo_discount?.toFixed(2)}</span>
      </article>
    ) : (
      <></>
    );
  };

  const _renderPaymentPromoDiscountBox = () => {
    return cart.payment_offer && cart.payment_offer.promo_discount ? (
      <article className="flex justify-between items-center f6 lime-green-color">
        <span>Bank Discount</span>
        <span>(-) ₹{cart.payment_offer.promo_discount?.toFixed(2)}</span>
      </article>
    ) : (
      <></>
    );
  };

  function _renderGstExclusivePrice() {
    return isStoreGstExcluded() ? (
      <GstExcludesContainer>
        <span>GST</span>
        <span>₹ {cart?.total_gst_paid}</span>
      </GstExcludesContainer>
    ) : null;
  }

  function _renderDeliveryRelatedInfo() {
    const isOrderBasedStoreType =
      getStoreType(storeInfo) === STORE_CUSTOMIZATION.ORDER_BASED;
    const isDeliveryOrder = cart.order_type === ORDER_TYPE.DELIVERY;
    const minDeliveryCharge = storeInfo?.services?.delivery_charge_min;
    const maxDeliveryCharge = storeInfo?.services?.delivery_charge_max;
    const approxDeliveryTime = storeInfo?.services?.delivery_time_approx;
    const isDeliveryChargeInfoAvailable = !!minDeliveryCharge && !!maxDeliveryCharge;
    const isDeliveryTimeInfoAvailable = !!approxDeliveryTime;
    const isDeliveryInfoAvaiable =
      isDeliveryChargeInfoAvailable || isDeliveryTimeInfoAvailable;

    const showDeliveryInfo =
      isOrderBasedStoreType && isDeliveryOrder && isDeliveryInfoAvaiable;

    return (
      showDeliveryInfo && (
        <article className="flex justify-center items-start pt4">
          <div className="flex w2">
            <img
              src="/assets/images/information@2x.png"
              alt="info ocon"
              className="img h1"
            />
          </div>
          <div className="flex flex-auto f7 black-50 ph2 lh-title">
            {isDeliveryChargeInfoAvailable && (
              <span
                style={{ marginBottom: 10 }}
              >{`Delivery charges may apply, approx delivery charge ₹ ${minDeliveryCharge} to ₹ ${maxDeliveryCharge}. Exact amount will be reflected in your final bill`}</span>
            )}
            {isDeliveryTimeInfoAvailable && (
              <span>{`Typically arrives in ${approxDeliveryTime}. Final ETA will be shared once we get your order ready.`}</span>
            )}
          </div>
        </article>
      )
    );
  }

  return (
    <AmountContainer>
      <AmountRow>
        <TotalText fontWeight={400}>{getCustomCtaText('item_total_label')}</TotalText>
        <TotalText fontWeight={400}>
          <span>
            {cart.items_total && `${CURRENCY_SYMBOL}${cart?.items_total?.toFixed(2)}`}
          </span>
        </TotalText>
      </AmountRow>
      <AmountRow
        style={{
          flexDirection: 'column',
          alignItems: 'space-between',
          justifyContent: 'center',
        }}
      >
        {cart.order_type === ORDER_TYPE.DELIVERY &&
          checkDeliveryChargeTypeComparison() && (
            <article
              style={{ width: '100%' }}
              className="cart-totalAmt__deliveryCharge flex justify-between items-start premium-deliver--charge mt2"
            >
              <div className="flex flex-column w-100">
                <DeliveryChargeDropDownContainer>
                  <TotalText fontWeight={400}>
                    Delivery fees
                    {isDeliveryPincodeBased() && cart.delivery_charge > 0 && (
                      <DropDownArrowContainer
                        onClick={handleDeliveryChargeList}
                        showDeliveryChargeList={showDeliveryChargeList}
                      >
                        <DropDownArrow width={12} height={6} />
                      </DropDownArrowContainer>
                    )}
                  </TotalText>
                  <TotalText fontWeight={400}>
                    {cart.delivery_charge === 0
                      ? 'Free'
                      : `${CURRENCY_SYMBOL} ${cart.delivery_charge}`}
                  </TotalText>
                </DeliveryChargeDropDownContainer>
                {showDeliveryChargeList && <DeliveryChargeList />}
              </div>
            </article>
          )}
      </AmountRow>
      <div className="w-100 mv2">{_renderPromoDiscountBox()}</div>
      <div className="w-100 mv2">{_renderPaymentPromoDiscountBox()}</div>
      <div className="w-100 mv2">{_renderGstExclusivePrice()}</div>
      {showGrandTotal && (
        <div className="w-100 mv2">
          <GrandTotal cart={cart} />
        </div>
      )}
      {_renderDeliveryRelatedInfo()}
    </AmountContainer>
  );
};

export default OrderAmountInfo;
