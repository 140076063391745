import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { setCart } from '@/redux/actions';
import { getCurrentOrderType } from '@/utils/getCurrentOrderType';
import { getStringifyAddress } from '@/utils/getStringifyAddress';

export default function useSetCart() {
  const dispatch = useDispatch();
  const storeId = useSelector((state) => state.storeReducer.store.store_id);
  const [cart, triggerSetCart] = useState({});

  useEffect(() => {
    const timerId = setTimeout(setCustomerCart, 300);
    return () => clearTimeout(timerId);
  }, [cart]);

  const setCustomerCart = () => {
    if (Object.keys(cart).length) {
      dispatch(
        setCart({
          ...cart,
          order_type: getCurrentOrderType(cart.order_type),
          store_id: storeId,
          user_phone: cart.phone,
          cart_id: cart.cart_id,
          delivery_info: getStringifyAddress(),
        })
      );
    }
  };

  return { triggerSetCart };
}
