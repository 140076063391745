import BasicPromoStrip from "../BasicPromoStrip";

export const PromosContainer = ({
  promos,
  onPromoClick,
  customClasses = '',
  primaryTextClasses = '',
  secondaryTextClasses = '',
  offerStripContainerClasses = '',
  offerStripCtaTextOrIcon = null,
  isStorePromos = true,
}) => {
  return (
    <div className={customClasses || ''}>
      {isStorePromos ? (
        <>
          {promos.map(({ id, description, code }, index) => (
            <BasicPromoStrip
              key={index}
              primaryText={description}
              secondaryText={code}
              ctaTextOrIcon={offerStripCtaTextOrIcon || 'Details'}
              onCtaClick={onPromoClick}
              promoId={id}
              primaryTextClasses={primaryTextClasses}
              secondaryTextClasses={secondaryTextClasses}
              customClasses={'tw-p-[16px] ' + offerStripContainerClasses}
            />
          ))}
        </>
      ) : (
        <>
          {promos.map(({ promo_id, promo_code, description }, index) => (
            <BasicPromoStrip
              key={index}
              primaryText={description}
              secondaryText={promo_code}
              ctaTextOrIcon={offerStripCtaTextOrIcon || 'Details'}
              onCtaClick={onPromoClick}
              promoId={promo_id}
              primaryTextClasses={primaryTextClasses}
              secondaryTextClasses={secondaryTextClasses}
              customClasses={'tw-p-[16px] ' + offerStripContainerClasses}
            />
          ))}
        </>
      )}
    </div>
  );
};
