/**
 * This component renders the list of payment promotions
 */

import React from 'react';
import Modal from '../Common/Modal';
import { deviceWidth } from '@/utils/deviceWidth';

export const PaymentPromoCouponDetailsModal = (props) => {
  return (
    <Modal
      visible={true}
      height="auto"
      animation={'slideUp'}
      onClose={props.onClose}
      className="bottom-modal paymentPromoDetailsModal"
      customMaskStyles={{
        background: '#000000e6',
      }}
      customStyles={{
        maxWidth: '450px',
      }}
    >
      <div className="pb4 vh-75 overflow-scroll">
        <span className="close-icon flex justify-center items-center">
          <img
            src="/assets/images/cancel_white.png"
            className="img h2 "
            onClick={props.onClose}
          />
        </span>
        <div className="f4 b mv3">Offer Details</div>
        {props.promoData?.image_url ? (
          <div className="flex items-center logo-coupon-desc">
            <img
              src={props.promoData.image_url}
              alt=""
              width="60px"
              height="60px"
              className="mv3 mr3"
            ></img>
            {props.promoData?.description && deviceWidth > 990 ? (
              <div className="f6 b">{props.promoData.description}</div>
            ) : null}
          </div>
        ) : null}
        {props.promoData?.description && deviceWidth < 990 ? (
          <div className="f6 b">{props.promoData.description}</div>
        ) : null}
        {props.promoData.promo_code ? (
          <div className="promoCodeBox fw4">{props.promoData.promo_code}</div>
        ) : null}
        {props.promoData.notes.length ? (
          <ul className="notesList">
            {props.promoData.notes.map((data, index) => (
              <li key={index} className="f4">
                {data}
              </li>
            ))}
          </ul>
        ) : null}
      </div>
    </Modal>
  );
};
