const TimeIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.5591 13.3357L13.3357 12.5591L10.5526 9.776V6.31579H9.44737V10.2238L12.5591 13.3357ZM10.0013 17C9.03308 17 8.12302 16.8163 7.2711 16.4488C6.41919 16.0814 5.67818 15.5827 5.04805 14.9529C4.41793 14.323 3.91903 13.5823 3.55134 12.7307C3.18378 11.8792 3 10.9694 3 10.0013C3 9.03308 3.18372 8.12302 3.55116 7.2711C3.9186 6.41919 4.41725 5.67818 5.04713 5.04805C5.67701 4.41793 6.41772 3.91903 7.26926 3.55134C8.12081 3.18378 9.03062 3 9.99871 3C10.9669 3 11.877 3.18372 12.7289 3.55116C13.5808 3.9186 14.3218 4.41725 14.9519 5.04713C15.5821 5.67701 16.081 6.41772 16.4487 7.26926C16.8162 8.12081 17 9.03062 17 9.99871C17 10.9669 16.8163 11.877 16.4488 12.7289C16.0814 13.5808 15.5827 14.3218 14.9529 14.9519C14.323 15.5821 13.5823 16.081 12.7307 16.4487C11.8792 16.8162 10.9694 17 10.0013 17ZM10 15.8947C11.6333 15.8947 13.0241 15.3206 14.1724 14.1724C15.3206 13.0241 15.8947 11.6333 15.8947 10C15.8947 8.36667 15.3206 6.97588 14.1724 5.82763C13.0241 4.67939 11.6333 4.10526 10 4.10526C8.36667 4.10526 6.97588 4.67939 5.82763 5.82763C4.67939 6.97588 4.10526 8.36667 4.10526 10C4.10526 11.6333 4.67939 13.0241 5.82763 14.1724C6.97588 15.3206 8.36667 15.8947 10 15.8947Z"
      fill="#4E4E4E"
    />
  </svg>
);

export default TimeIcon;
