import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';

const useTriggerCartPixelEvent = ({ orderButtonClickCount }) => {
  const { cart, userData } = useSelector((state: RootState) => ({
    cart: state.cartReducer,
    userData: state.userReducer,
  }));

  useEffect(() => {
    if (typeof window.fbq === 'function' && orderButtonClickCount == 1) {
      const pixelObject = {
        contents: cart?.items?.map((item) => {
          return {
            id: item?.id || item?.item_id,
            name: item?.name || item?.item_name,
            price: item?.price || item?.actual_price,
            quantity: item?.quantity,
          };
        }),
        currency: 'INR',
        num_items: cart?.items?.length,
        value: cart?.pay_amount,
        user_id: userData?.data?.user_id,
        phone: userData?.data?.phone,
      };
      window.fbq('track', 'Purchase', pixelObject);
    }
  }, [orderButtonClickCount]);

  return null;
};

export default useTriggerCartPixelEvent;
