import { useEffect, useState } from 'react';

/**
 * This Custom Hook Calculates if an Overflow div
 * is Scrolled To the end by User. USed in Cart.
 * @param {*} props
 * @returns -> False : When User reaches end of the Div. else False.
 */

export function useOnScrollDownCta(props) {
  const [showScrollDownHelper, setShowScrollDownHelper] = useState(true);
  const referenceToCheckScrollHeight = props.referenceToCheckScrollHeight;

  useEffect(() => {
    referenceToCheckScrollHeight?.current?.addEventListener(
      'scroll',
      checkIfScrolledDown
    );
    checkIfScrolledDown();
    referenceToCheckScrollHeight?.current?.addEventListener('click', checkScrollHeight);
    return () => {
      removeScrollEventListener();
      removeClickEvent();
    };
  }, []);

  function removeScrollEventListener() {
    referenceToCheckScrollHeight?.current?.removeEventListener(
      'scroll',
      checkIfScrolledDown
    );
  }

  function removeClickEvent() {
    referenceToCheckScrollHeight?.current?.removeEventListener(
      'click',
      checkScrollHeight
    );
  }

  function checkScrollHeight() {
    if (referenceToCheckScrollHeight?.current?.scrollHeight < 700) {
      setShowScrollDownHelper(false);
      removeScrollEventListener();
      removeClickEvent();
    }
  }

  function checkIfScrolledDown() {
    if (
      referenceToCheckScrollHeight?.current?.offsetHeight +
        referenceToCheckScrollHeight?.current?.scrollTop +
        20 >=
      referenceToCheckScrollHeight?.current?.scrollHeight
    ) {
      setShowScrollDownHelper(false);
      removeScrollEventListener();
      removeClickEvent();
    }
  }
  return showScrollDownHelper;
}
