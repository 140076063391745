import styled from 'styled-components';

export const ScrollContainer = styled.div`
  display: flex;
  justify-content: center;
  position: sticky;
  bottom: 2%;
  left: 40%;
`;
export const ScrollButton = styled.div`
  background: #383838;
  opacity: 0.76;
  display: flex;
  justify-content: center;
  max-width: 10vw;
  border-radius: 18px;
`;

export const ScrollTextIconContainer = styled.div`
  display: flex;
  font-size: 12px;
  padding: 10px 16px 10px 16px;
  color: white;
`;

export const ScrollIcon = styled.div`
  display: flex;
  margin-left: 9px;
  margin-top: 4px;
`;
