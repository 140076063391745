import { removeItemFromCart } from '@/utils/removeItemFromCart';
import { SimpleCloseIcon } from '@/assets/svgExports/SimpleCloseIcon';
import { triggerGetServiceableAddressId } from '@/utils/triggerGetServiceableAddressId';
import {
  CartRemoveCtaContainer,
  CartRemoveIcon,
  CartRemoveText,
} from './CartRemoveCta.styles';

function CartRemoveCta(props) {
  const cartRemoveCtaContainerStyles = {
    ml: props.ml ? props.ml : 0,
    mt: props.mt ? props.mt : 0,
    mb: props.mb ? props.mb : 0,
    mr: props.mr ? props.mr : 0,
  };

  function handleRemoveCta() {
    removeItemFromCart(props?.item);
    triggerGetServiceableAddressId();
  }

  return (
    <CartRemoveCtaContainer
      customStyles={cartRemoveCtaContainerStyles}
      onClick={handleRemoveCta}
    >
      <CartRemoveIcon mti={2} mri={6}>
        <SimpleCloseIcon />
      </CartRemoveIcon>
      <CartRemoveText fontSize={props.fontSize}>Remove</CartRemoveText>
    </CartRemoveCtaContainer>
  );
}

export default CartRemoveCta;
