import React from 'react';
import { useSelector } from 'react-redux';
import { CURRENCY_SYMBOL } from 'src/config';
import {
  DeliveryChargeListContainer,
  DeliveryChargeRow,
  DeliveryPriceContainer,
} from './DeliveryChargeList.styles';

function DeliveryChargeList() {
  const { items, otherDeliveryCharges, cartData } = useSelector(state => ({
    items: state.cartReducer.items,
    otherDeliveryCharges: state.cartReducer.other_delivery_charges,
    cartData: state.cartReducer,
  }));
  return (
    <DeliveryChargeListContainer>
      {items?.length > 0
        ? items?.map(item => {
            return (
              <React.Fragment key={item.item_id}>
                {item.delivery_charges > 0 ? (
                  <DeliveryChargeRow>
                    {item.item_name}
                    <DeliveryPriceContainer>
                      {CURRENCY_SYMBOL}
                      {item.delivery_charges}
                    </DeliveryPriceContainer>
                  </DeliveryChargeRow>
                ) : null}
              </React.Fragment>
            );
          })
        : null}
      {otherDeliveryCharges > 0 &&
        otherDeliveryCharges !== cartData?.delivery_charge && (
          <DeliveryChargeRow>
            Other Delivery Charges
            <DeliveryPriceContainer>
              {CURRENCY_SYMBOL}
              {otherDeliveryCharges}
            </DeliveryPriceContainer>
          </DeliveryChargeRow>
        )}
    </DeliveryChargeListContainer>
  );
}

export default DeliveryChargeList;
