import store from '../redux/store';
import { setCart } from '@/redux/actions';

/**
 * removes a itemId from cart.
 */
export function removeItemFromCart(productItem) {
  const cartData = store.getState().cartReducer;
  const items = cartData?.items?.filter((item) => productItem?.item_id !== item?.item_id);
  const newCart = { ...cartData, items };
  store.dispatch(setCart(newCart));
}
