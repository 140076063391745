export function OtherDarkIcon() {
  return (
    <svg
      width="11"
      height="11"
      viewBox="0 0 11 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_10_4884)">
        <path
          d="M5.125 0C6.21857 0.00145065 7.26694 0.436513 8.04021 1.20979C8.81349 1.98306 9.24855 3.03143 9.25 4.125C9.25 7.0864 5.40688 10.7955 5.24359 10.9519C5.21183 10.9827 5.16929 11 5.125 11C5.08071 11 5.03817 10.9827 5.00641 10.9519C4.84313 10.7955 1 7.0864 1 4.125C1.00145 3.03143 1.43651 1.98306 2.20979 1.20979C2.98306 0.436513 4.03143 0.00145065 5.125 0ZM5.125 6.01562C5.49893 6.01562 5.86446 5.90474 6.17537 5.69699C6.48629 5.48925 6.72861 5.19398 6.87171 4.84851C7.01481 4.50304 7.05225 4.1229 6.9793 3.75616C6.90635 3.38941 6.72628 3.05253 6.46187 2.78813C6.19747 2.52372 5.86059 2.34365 5.49384 2.2707C5.1271 2.19775 4.74696 2.23519 4.40149 2.37829C4.05602 2.52139 3.76075 2.76371 3.553 3.07462C3.34526 3.38554 3.23438 3.75107 3.23438 4.125C3.23468 4.62633 3.43396 5.10704 3.78846 5.46154C4.14295 5.81604 4.62367 6.01532 5.125 6.01562Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_10_4884">
          <rect width="11" height="11" fill="white" transform="matrix(-1 0 0 1 11 0)" />
        </clipPath>
      </defs>
    </svg>
  );
}
