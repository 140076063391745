import styled from 'styled-components';

export const DeliveryChargeListContainer = styled.div`
  display: flex;
  margin-top: 6px;
  flex-direction: column;
`;

export const DeliveryChargeRow = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: #7e7e7e;
  width: 100%;
  margin-top: 6px;
`;

export const DeliveryPriceContainer = styled.div`
  display: flex;
  font-size: 14px;
  color: #7e7e7e;
`;
