import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import {
  ItemContainer,
  ItemList,
  ItemPrecursor,
  ItemPrecursorText,
} from '../../cart.styles';
import getCustomCtaText from 'src/utils/getCustomCtaText';
import Scroll from 'src/components/Scroll/Scroll';
import CartItem from 'src/components/DesignSystem/CartItem';

const AllCartItems = ({ referenceToCheckScrollHeight, allItems }) => {
  return (
    <ItemContainer className="!tw-max-w-[100vw]">
      {IS_DESKTOP && (
        <ItemPrecursor>
          <ItemPrecursorText>
            {getCustomCtaText('item_details_heading')}
          </ItemPrecursorText>
          <ItemPrecursorText
            style={{
              marginRight: '6px',
            }}
          >
            Quantity
          </ItemPrecursorText>
        </ItemPrecursor>
      )}
      <ItemList>
        {allItems?.length > 0 &&
          allItems
            .filter((item) => item.item_type === 'catalog')
            .map((item, i) => {
              if (item?.variants_selected?.length > 0) {
                return item?.variants_selected?.map((_var, varIdx) => (
                  <CartItem key={_var.variant_id} data={item} variant={_var} />
                ));
              } else return <CartItem key={`${i}-cart-item`} data={item} />;
            })}
        <Scroll referenceToCheckScrollHeight={referenceToCheckScrollHeight} />
      </ItemList>
    </ItemContainer>
  );
};

export default AllCartItems;
