import {
  DropDownArrowSummary,
  ItemHeading,
  OrderSummary,
  OrderSummaryText,
  TotalDropDownArrowContainer,
} from '../../cart.styles';
import getCustomCtaText from 'src/utils/getCustomCtaText';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import { KeyboardArrowDown } from '@mui/icons-material';

const CartItemsAndCheckoutSectionHeading = ({
  showSummary,
  hanldeSummary,
  summeryClasses = '',
  showDropdown = false,
}) => {
  return (
    !IS_DESKTOP && (
      <ItemHeading
        onClick={() => showDropdown && hanldeSummary()}
        showSummery={showSummary}
        showDropdown={showDropdown}
      >
        <OrderSummary>
          <OrderSummaryText
            className={`${showDropdown ? 'tw-text-[18px]/[22px]' : 'tw-text-[16px]/[20px]'} tw-font-semibold md:tw-font-medium ${summeryClasses}`}
          >
            {getCustomCtaText('order_summary_heading')}
          </OrderSummaryText>
        </OrderSummary>
        {!IS_DESKTOP && showDropdown && (
          <TotalDropDownArrowContainer>
            <DropDownArrowSummary showOrderSummary={showSummary}>
              <KeyboardArrowDown />
            </DropDownArrowSummary>
          </TotalDropDownArrowContainer>
        )}
      </ItemHeading>
    )
  );
};

export default CartItemsAndCheckoutSectionHeading;
