import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import styled from 'styled-components';

export const AddressFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid #e8e8eb;
  border-radius: 8px;
  @media screen and (max-width: 767px) {
    border: none;
    border-top: ${(props) => (props.showBorderTop ? '1px solid #e8e8eb' : 'none')};
    border-radius: 0px;
    margin-top: 0px;
  }
`;

export const AddressCtaText = styled.div`
  display: flex;
  font-weight: 600;
  font-size: 14px;
  color: ${(props) => props.color || '#000000'};
`;

export const AddressTabContainer = styled.div`
  display: flex;
  border-radius: ${(props) => (props.instructionPopup ? '0px' : '8px')};
  margin-top: ${(props) => (props.instructionPopup || IS_DESKTOP ? '0px' : '16px')};
  justify-content: space-between;
  min-height: 64px;
  @media screen and (max-width: 767px) {
    width: 100vw;
    border-left: none;
    border-right: none;
    border-bottom: none;
    border-radius: 0px;
    margin-top: 0px;
  }
`;

export const AddressTabLeftIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 20px;
`;

export const AddressLineContainer = styled.div`
  display: flex;
  flex-direction: column;
  ${(props) =>
    !props.toggleAddDeliveryForm && `padding-top : 20px ; padding-bottom : 20px`};
`;

export const AddressLineOneContainer = styled.div`
  display: flex;
  color: #7f7f7f;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const AddressLineTwoContainer = styled.div`
  display: flex;
  font-weight: 700;
  font-size: 14px;
  margin-left: 6px;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    font-size: 12px;
    margin-top: 8px;
    margin-left: 0px;
  }
`;

export const AddressMainLineContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
`;
