const DateIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_4_1343)">
      <path
        d="M7.66667 10H6.5C5.85658 10 5.33333 10.5232 5.33333 11.1667V12.3333C5.33333 12.9767 5.85658 13.5 6.5 13.5H7.66667C8.31008 13.5 8.83333 12.9767 8.83333 12.3333V11.1667C8.83333 10.5232 8.31008 10 7.66667 10ZM6.5 12.3333V11.1667H7.66667V12.3333H6.5ZM14.0833 4.16667H13.5V3.58333C13.5 3.26133 13.2392 3 12.9167 3C12.5941 3 12.3333 3.26133 12.3333 3.58333V4.16667H7.66667V3.58333C7.66667 3.26133 7.40592 3 7.08333 3C6.76075 3 6.5 3.26133 6.5 3.58333V4.16667H5.91667C4.30842 4.16667 3 5.47508 3 7.08333V14.0833C3 15.6916 4.30842 17 5.91667 17H14.0833C15.6916 17 17 15.6916 17 14.0833V7.08333C17 5.47508 15.6916 4.16667 14.0833 4.16667ZM5.91667 5.33333H14.0833C15.0482 5.33333 15.8333 6.1185 15.8333 7.08333V7.66667H4.16667V7.08333C4.16667 6.1185 4.95183 5.33333 5.91667 5.33333ZM14.0833 15.8333H5.91667C4.95183 15.8333 4.16667 15.0482 4.16667 14.0833V8.83333H15.8333V14.0833C15.8333 15.0482 15.0482 15.8333 14.0833 15.8333Z"
        fill="#4E4E4E"
      />
    </g>
    <defs>
      <clipPath id="clip0_4_1343">
        <rect width="14" height="14" fill="white" transform="translate(3 3)" />
      </clipPath>
    </defs>
  </svg>
);

export default DateIcon;
