import moment from 'moment';

export function formatDate(date) {
  return moment(date).format('Do MMMM, YYYY');
}

export function parseFormattedDate(formattedDate) {
  if (!formattedDate) {
    return null;
  }
  const date = moment(formattedDate, 'Do MMMM, YYYY', true);
  return date.isValid() ? date.toDate() : null;
}

export function convertTo12HourFormat(time24) {
  return moment(time24, 'HH:mm').format('h:mm A');
}
