import { capitalize } from 'lodash';
import { useSelector } from 'react-redux';
import { PickupIcon } from 'src/containers/Cart/SVGs';
import { RootState } from 'src/redux/reducers';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import getDummyOrActualContactDetails from 'src/utils/getContactDetails';
import { getThemeColor } from 'src/utils/getThemeColor';

const TabPickup = (props) => {
  const storeData = useSelector((state: RootState) => state.storeReducer.store);
  const { storeAddress, storePhone } = getDummyOrActualContactDetails(storeData, true);

  function getPickupAvailableText() {
    return (
      <p className="tw-m-0 tw-flex tw-pt-[12px] tw-text-xs tw-italic md:tw-p-0">
        Check with store if pickup is available today
      </p>
    );
  }

  return (
    <div
      className={`tw-mb-[16px] tw-rounded-lg md:tw-border ${props.showTopBorderMobile ? 'tw-border-t tw-border-solid' : 'tw-bodrer-none'} tw-border-[#EAEAEC] md:tw-mb-0 md:tw-mt-[16px]   md:tw-border-solid`}
    >
      <div className="tw-flex tw-items-center tw-justify-between tw-px-[16px] tw-py-[20px]">
        <span className="tw-flex tw-items-center tw-gap-[12px] tw-text-[16px] tw-font-semibold md:tw-text-sm md:tw-font-medium">
          {IS_DESKTOP && <PickupIcon />}
          Store Location
        </span>
        {IS_DESKTOP && getPickupAvailableText()}
      </div>
      <div className="tw-px-[16px] md:tw-border-t md:tw-border-solid md:tw-border-[#EAEAEC] md:tw-px-[32px] md:tw-pb-[24px] md:tw-pt-[24px] ">
        <div
          className={
            'tw-flex tw-flex-col tw-gap-[12px] tw-rounded-lg tw-border tw-border-solid tw-border-[#E2E2E2] tw-pt-[12px] md:tw-border-none md:tw-p-0 '
          }
        >
          <div className="tw-flex tw-flex-col tw-gap-[4px] tw-px-[16px] md:tw-p-0">
            <p className="tw-m-0 tw-text-sm">{capitalize(storeData.store_info?.name)}</p>
            <p className="tw-m-0 tw-text-sm  tw-text-[#7D7D7D]">{storePhone}</p>
            <p className="tw-m-0 tw-text-sm  tw-text-[#7D7D7D]">{storeAddress}</p>
            {!IS_DESKTOP && getPickupAvailableText()}
          </div>
          {!IS_DESKTOP && (
            <div className="tw-flex tw-divide-x tw-border-t tw-border-solid tw-border-[#E2E2E2] md:tw-flex-row-reverse md:tw-gap-[16px] md:tw-border-none">
              <a
                className="tw-flex tw-flex-1 tw-cursor-pointer tw-justify-center tw-border-r tw-border-solid tw-border-[#E2E2E2] tw-px-[16px] tw-py-[12px] tw-text-sm tw-font-semibold tw-no-underline last:tw-border-none md:tw-flex-none md:tw-border-none md:tw-p-0"
                style={{ color: getThemeColor() }}
                href={`tel:${storePhone}`}
              >
                Call Store
              </a>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TabPickup;
