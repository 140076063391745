import { useState, useEffect } from 'react';

/*
  Returns isComponentMounted
  isComponentMounted -> true / Component is Successfully mounted
*/

function useIsComponentMounted() {
  const [isComponentMounted, setIsComponentMounted] = useState(false);

  useEffect(() => {
    setIsComponentMounted(true);
  }, []);

  return isComponentMounted;
}

export default useIsComponentMounted;
