import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import {
  CardHeaderInner,
  CartHeader,
  CartHeaderBack,
  CartHeaderData,
  CartHeaderText,
} from '../../cart.styles';
import { useRouter } from 'next/router';
import { specificThemeRender } from 'src/utils/specificThemeRender';
import { getThemeComponent } from 'src/utils/themeImports';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { BackArrow } from 'src/assets/svgExports/BackArrow';

const PageHeader = ({ isEmptyCart }) => {
  const { storeInfo } = useSelector((state: RootState) => ({
    storeInfo: state.storeReducer.store,
  }));
  const router = useRouter();
  
  return !IS_DESKTOP ? (
    <CartHeader>
      <CartHeaderBack
        onClick={() => router.back()}
      >
        <BackArrow width={16} height={16} />
      </CartHeaderBack>
      <CartHeaderData>
        {!isEmptyCart ? (
          <CardHeaderInner>
            <CartHeaderText>Cart</CartHeaderText>
          </CardHeaderInner>
        ) : null}
      </CartHeaderData>
    </CartHeader>
  ) : (
    specificThemeRender(storeInfo)?.components?.Header?.map((layout) => {
      return getThemeComponent(layout.sub_type, layout.name, layout);
    })
  );
};

export default PageHeader;
