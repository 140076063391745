import { useEffect, useState } from 'react';
import BankOffersSection from './OffersSections/BankOffersSection';
import { useDispatch, useSelector } from 'react-redux';
import { getCartPagePromos, getPaymentOffersList } from 'src/redux/actions';
import { RootState } from 'src/redux/reducers';
import { ExpandOrShrinkButton } from '../Common';
import StoreOffersSection from './OffersSections/StoreOffersSection';
import { debounce } from 'src/utils/debounce';

function getCartPromos(cartData, dispatch, callabck) {
  const payload = {
    cart: cartData,
  };
  dispatch(getCartPagePromos(payload, callabck));
}

const debouncedGetCartPromos = debounce(getCartPromos, 300);

const OfferNotAppliedView = ({
  activeTabIndex,
  setSuccessPopupState,
  showView,
  setUnOpenedTabText,
}) => {
  const dispatch = useDispatch();
  const [showAllPromos, setShowAllPromos] = useState(false);
  const [cartStorePromos, setCartStorePromos] = useState([]);

  const { paymentOffersList, storeData, cartData, latestSyncedCart } = useSelector(
    (state: RootState) => ({
      paymentOffersList: state.promoReducer.paymentOffersList || [],
      storeData: state.storeReducer.store,
      cartData: state.cartReducer,
      latestSyncedCart: state.commonReducer.latestSyncedCart,
    })
  );

  useEffect(() => {
    if (latestSyncedCart && latestSyncedCart?.items?.length && storeData?.store_id) {
      debouncedGetCartPromos(cartData, dispatch, (allPromos) =>
        setCartStorePromos(allPromos)
      );
    }
  }, [latestSyncedCart, storeData?.store_id]);

  useEffect(() => {
    if (cartStorePromos?.length || paymentOffersList?.length) {
      setUnOpenedTabText(
        `${cartStorePromos?.length + paymentOffersList?.length} Offers Available`
      );
    }
  }, [cartStorePromos, paymentOffersList]);

  useEffect(() => {
    if (storeData?.store_id) {
      dispatch(getPaymentOffersList(storeData.store_id));
    }
  }, [storeData?.store_id]);

  const isStoreOfferTabActive = activeTabIndex === 0;

  return (
    showView && (
      <div className="tw-flex tw-flex-col tw-gap-[18px] tw-px-[12px] tw-py-[16px] md:tw-gap-[16px] md:tw-p-[24px]">
        {isStoreOfferTabActive ? (
          <StoreOffersSection
            showAllPromos={showAllPromos}
            cartStorePromos={cartStorePromos}
            cartData={cartData}
            setSuccessPopupState={setSuccessPopupState}
          />
        ) : (
          <BankOffersSection
            showAllPromos={showAllPromos}
            paymentOffersList={paymentOffersList}
            cartData={cartData}
            setSuccessPopupState={setSuccessPopupState}
          />
        )}
        <ExpandOrShrinkButton
          showAllPromos={showAllPromos}
          setShowAllPromos={setShowAllPromos}
          show={
            isStoreOfferTabActive
              ? cartStorePromos?.length > 3
              : paymentOffersList?.length > 3
          }
        />
      </div>
    )
  );
};

export default OfferNotAppliedView;
