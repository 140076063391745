import store from '../redux/store';

/**
 * object containing unservicble , servicable and pincode required addresses
 * @returns {object} Properties -> Servicable, unservicable , pincode , Address Id's
 */

export function getAddressByServiceabilityUnserviceabilty() {
  const pincode_required = [];
  const serviceable_address_ids = [];
  const unserviceable_address_ids = [];
  const addressData = store.getState().deliveryReducer.addressData;
  const userData = store.getState().userReducer;
  for (let i = 0; i < userData?.addressList?.length; i++) {
    if (addressData?.pincode_required?.includes(userData?.addressList?.[i]?.address_id)) {
      pincode_required.push(userData?.addressList?.[i]);
    } else if (
      addressData?.serviceable_address_ids?.includes(
        userData?.addressList?.[i]?.address_id
      )
    ) {
      serviceable_address_ids.push(userData?.addressList?.[i]);
    } else if (
      addressData?.unserviceable_address_ids?.includes(
        userData?.addressList?.[i]?.address_id
      )
    ) {
      unserviceable_address_ids.push(userData?.addressList?.[i]);
    }
  }
  return {
    pincode_required: pincode_required,
    serviceable_address_ids: serviceable_address_ids,
    unserviceable_address_ids: unserviceable_address_ids,
  };
}
