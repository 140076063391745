import { CartOption, Text } from '@/containers/Cart/cart.styles';
import { ORDER_TYPE } from '@/utils/constants';
import React from 'react';
import { useSelector } from 'react-redux';
import TabAddress from './Components/TabAddress/TabAddress';
import CheckoutInfoTabOrderInstruction from './Components/TabOrderInstruction/TabOrderInstruction';
import CheckoutInfoTabPromo from './Components/TabPromo/TabPromo';
import {
  CheckoutAdditionalInfoContainer,
  CheckoutAdditionalInfoHeaderContainer,
  CheckoutAdditionalInfoTabContainer,
} from './CartAdditionalInfo.styles';
import { isStoreServiceBased } from '@/utils/isStoreServiceBased';
import { isAddressMandatoryForService } from '@/utils/isAddressMandatoryForService';
import DesktopPaymentModal from 'src/components/DesktopPaymentModal';
import PaymentModal from 'src/components/PaymentModal';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import OrderTypeSelector from './Components/OrderTypeSelector';
import TabPickup from './Components/TabPickup';

function CheckoutAdditionalInfo(props) {
  const cartData = useSelector((state) => state.cartReducer);
  const storeInfo = useSelector((state) => state.storeReducer.store);

  const shouldShowAddressField =
    cartData?.order_type === ORDER_TYPE.DELIVERY ||
    (isStoreServiceBased() && isAddressMandatoryForService());
  const shouldShowPickuptab = cartData?.order_type === ORDER_TYPE.PICKUP;

  const showTopBorderMobile =
    !storeInfo?.services?.pickup_flag || !storeInfo?.services?.delivery_flag;

  const _renderAddressField = () => {
    return shouldShowAddressField ? (
      <TabAddress showTopBorderMobile={showTopBorderMobile} />
    ) : null;
  };

  const _renderPickupTab = () => {
    return shouldShowPickuptab ? (
      <TabPickup showTopBorderMobile={shouldShowAddressField} />
    ) : null;
  };

  function getOrderTypeText() {
    switch (cartData?.order_type) {
      case ORDER_TYPE.PICKUP:
        return 'Pickup Order';
      case ORDER_TYPE.DELIVERY:
        return 'Delivery Order';
      default:
        return null;
    }
  }

  const selectedOrderTypeText = getOrderTypeText();

  return (
    <>
      <CheckoutAdditionalInfoContainer>
        <CheckoutAdditionalInfoHeaderContainer>
          {!!props.allItems?.length && IS_DESKTOP && (
            <CartOption className="tw-flex tw-items-center tw-justify-between">
              <Text>Cart</Text>
              {selectedOrderTypeText && (
                <Text className="!tw-text-sm !tw-font-medium">
                  {selectedOrderTypeText}
                </Text>
              )}
            </CartOption>
          )}
        </CheckoutAdditionalInfoHeaderContainer>
        <CheckoutAdditionalInfoTabContainer>
          <CheckoutInfoTabPromo />
          <div className="tw-flex tw-flex-col tw-gap-[]">
            <OrderTypeSelector />
            {_renderPickupTab()}
            {_renderAddressField()}
          </div>
          <CheckoutInfoTabOrderInstruction />
        </CheckoutAdditionalInfoTabContainer>
      </CheckoutAdditionalInfoContainer>
      {IS_DESKTOP ? <DesktopPaymentModal /> : <PaymentModal />}
    </>
  );
}

export default CheckoutAdditionalInfo;
