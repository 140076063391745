import { InfoIcon } from '@/assets/svgExports/InfoIcon';
import { CartErrorContainer, ErrorText, IconContainer } from './CartError.styles';

function CartError(props) {
  const cartErrorContainerStyles = {
    mb: props.mb ? props.mb : 0,
    mt: props.mt ? props.mt : 0,
    ml: props.ml ? props.ml : 0,
    mr: props.mr ? props.mr : 0,
    mobileMb: props.mobileMb ? props.mobileMb : '0px',
    width: props.width ? props.width : 'auto',
  };

  const iconContainerStyles = {
    mbi: props.mbi ? props.mbi : 0,
    mti: props.mti ? props.mti : 0,
    mli: props.mli ? props.mli : 0,
    mri: props.mri ? props.mri : 0,
    transXi: props.transXi ? props.transXi : '0px',
  };

  return (
    <CartErrorContainer
      customStyles={cartErrorContainerStyles}
      className={props?.customClasses || ''}
    >
      <IconContainer customStyles={iconContainerStyles}>
        <InfoIcon
          secondaryColor={'#FC3D3D'}
          height={props.iconHeight}
          width={props.iconWidth}
        />
      </IconContainer>
      <ErrorText fontSize={props.fontSize} transX={props.transX}>
        {props.text}
      </ErrorText>
    </CartErrorContainer>
  );
}

export default CartError;
