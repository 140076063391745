import styled from 'styled-components';

export const CheckoutAdditionalInfoContainer = styled.div`
  display: flex;
  flex: auto;
  flex-direction: column;
  overflow: visible;
  @media screen and (min-width: 767px) {
    padding: 0 32px;
    margin: 0 auto;
    max-width: 55%;
    overflow: scroll;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    /* Hide scrollbar for IE, Edge and Firefox */
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const CheckoutAdditionalInfoTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 6px;
  padding-bottom: 40px;
  @media screen and (max-width: 767px) {
    margin-top: 0px;
    padding-bottom: 0px;
  }
`;

export const CheckoutAdditionalInfoHeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
