import React from 'react';
import { Tab } from '../Common';

interface TabsContainerProps {
  activeTabIndex: number;
  setActiveTabIndex: (data: number) => void;
  tabsData: {
    text?: string;
    icon?: React.JSX.Element;
  }[];
  customClasses?: string;
  tabClasses?: string;
}

const TabsContainer: React.FC<TabsContainerProps> = ({
  activeTabIndex,
  setActiveTabIndex,
  tabsData,
  customClasses = '',
  tabClasses = '',
}) => {
  return (
    <div className={`tw-flex tw-px-[18px] tw-transition-all ${customClasses}`}>
      {tabsData.map(({ text }, index) => (
        <Tab
          key={index}
          text={text}
          isActive={index === activeTabIndex}
          onClick={() => setActiveTabIndex(index)}
          customClasses={tabClasses}
        />
      ))}
    </div>
  );
};

export default TabsContainer;
