export const ShippingTruck = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || '18.938'}
      height={props.height || '13.752'}
      viewBox="0 0 18.938 13.752"
    >
      <path
        id="local_shipping_FILL0_wght400_GRAD0_opsz48"
        d="M5.96,21.752A2.535,2.535,0,0,1,3.42,19.212H2V9.291a1.239,1.239,0,0,1,.387-.9A1.239,1.239,0,0,1,3.291,8h12.46v3.594h2.26l2.927,3.9v3.723H19.41a2.541,2.541,0,0,1-4.336,1.8,2.448,2.448,0,0,1-.742-1.8H8.5A2.535,2.535,0,0,1,5.96,21.752Zm0-1.291a1.248,1.248,0,1,0-.882-2.131A1.248,1.248,0,0,0,5.96,20.46ZM3.291,17.921h.473A2.851,2.851,0,0,1,4.69,17a2.422,2.422,0,0,1,2.507.011,3,3,0,0,1,.936.915H14.46V9.291H3.291ZM16.871,20.46a1.249,1.249,0,1,0-.882-.366A1.2,1.2,0,0,0,16.871,20.46Zm-1.119-4.39h4l-2.389-3.185H15.752ZM8.887,13.832Z"
        transform="translate(-2 -8)"
      />
    </svg>
  );
};
