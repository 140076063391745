import CartError from '@/components/CartError/CartError';
import AddDelivery from '@/containers/AddDelivery';
import DeliveryList from '@/containers/DeliveryList';
import {
  getEstimatedDeliveryTime,
  saveSelectedAddress,
  setAddDeliveryForm,
} from '@/redux/actions';
import { isDeliveryPincodeBased } from '@/utils/isDeliveryPincodeBased';
import { getStringifyAddress } from '@/utils/getStringifyAddress';
import { deviceWidth } from '@/utils/deviceWidth';
import { ShippingTruck } from '@/assets/svgExports/ShippingTruck';
import { triggerGetItemDeliveryCharges } from '@/utils/triggerGetItemDeliveryChages';
import { triggerGetServiceableAddressId } from '@/utils/triggerGetServiceableAddressId';
import React, { useContext, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  CheckoutInfoTabLeftContainer,
  CheckoutInfoTabMiddleContainer,
  CheckoutInfoTabRightContainer,
} from '../CheckoutInfoTab.styles';
import {
  AddressCtaText,
  AddressFormContainer,
  AddressTabContainer,
  AddressTabLeftIcon,
} from './TabAddress.styles';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import UserAddressCard from 'src/components/UserAddressCard';
import { CartContext } from 'src/context/CartContext';
import { ESTIMATE_DELIVERY_FLAG } from 'src/components/CheckItemDelivery/constants';

function CheckoutInfoTabAddress(props) {
  const dispatch = useDispatch();
  const { showAuthModal = null, showAddressError } = useContext(CartContext);

  const { userData, storeData, cartData, toggleAddDeliveryForm } = useSelector(
    (state) => ({
      userData: state.userReducer,
      storeData: state.storeReducer.store,
      cartData: state.cartReducer,
      toggleAddDeliveryForm: state.commonReducer.toggleAddDeliveryForm,
    })
  );
  const [newUser, setNewUser] = useState(false);
  const [showDeliveryList, setShowDeliveryList] = useState(false);
  const [editId, setEditId] = useState(0);
  const [fetchedLatestSavedAddress, setFetchedLatestSavedAddress] = useState({});

  const [estimatedDeliveryTime, setEstimatedDeliveryTime] = useState({
    pincode: '',
    deliveryTime: '',
  });

  const selectedAddressPincode = userData?.savedAddress?.pincode;

  useEffect(() => {
    if (!!fetchedLatestSavedAddress?.missing_fields?.length) {
      setEditId(fetchedLatestSavedAddress?.address_id);
      dispatch(setAddDeliveryForm(true));
    } else {
      dispatch(setAddDeliveryForm(false));
    }
  }, [fetchedLatestSavedAddress]);

  useEffect(() => {
    // get Estimated delivery time
    if (
      isDeliveryPincodeBased() &&
      selectedAddressPincode &&
      selectedAddressPincode !== estimatedDeliveryTime.pincode &&
      storeData?.services?.delivery_estimate_flag === ESTIMATE_DELIVERY_FLAG.ACTIVE
    ) {
      const payload = {
        id: storeData?.store_id,
        text: selectedAddressPincode,
      };

      dispatch(
        getEstimatedDeliveryTime(payload, (data) => {
          setEstimatedDeliveryTime({
            pincode: selectedAddressPincode,
            deliveryTime: data,
          });
        })
      );
    }
    // if user has no saved address save the first address in Address List
    if (!getStringifyAddress(userData?.savedAddress)?.address1) {
      dispatch(saveSelectedAddress(userData?.addressList?.[0]));
      dispatch(setAddDeliveryForm(false));
      setEditId(userData?.addressList?.[0]?.address_id ?? 0);
    } else {
      // Else update the saved address to latest fetched address
      const savedAddress = userData?.savedAddress;
      const selectedSavedAddress = userData?.addressList?.find(
        (add) => add?.address_id === savedAddress?.address_id
      );
      if (selectedSavedAddress) {
        setFetchedLatestSavedAddress(selectedSavedAddress);
        setEditId(selectedSavedAddress?.address_id ?? 0);
      }
    }
    // if address List is empty consider it New user and open add delivery Form
    if (userData?.addressList?.length === 0) {
      setNewUser(true);
    }
    // As soon as First address is added the user is no longer a new user. Close add delivery form.
    if (userData?.addressList?.length === 1) {
      dispatch(setAddDeliveryForm(false));
      setNewUser(false);
    }
    // addressList becomes >1 for any user its old user.
    if (userData?.addressList?.length > 1) setNewUser(false);
    // if savedAddress has missing any mandatory field open Add Delivery form to enter details.
    if (userData?.isLogin && fetchedLatestSavedAddress?.missing_fields?.length)
      dispatch(setAddDeliveryForm(true));
    // if user is Logged in And has some addresses get Item Charges in cart and servicable and unservicable addresses.
    if (userData?.isLogin && userData?.addressList?.length && isDeliveryPincodeBased()) {
      // if pincode is thier then only call getITemsDelivery corresponding to pincodes
      if (selectedAddressPincode) triggerGetItemDeliveryCharges();
      triggerGetServiceableAddressId();
    }
  }, [userData?.savedAddress, userData?.addressList, userData?.isLogin]);

  useEffect(() => {
    if (showAddressError) {
      document.getElementById('cart-address-section').scrollTo({ top: 80 });
    }
  }, [showAddressError]);

  function toggleShowDeliveryList(e = undefined) {
    e?.stopPropagation();
    setShowDeliveryList(!showDeliveryList);
  }

  function handleEditId(edit_id) {
    setEditId(edit_id);
  }

  function renderSelectedAddress() {
    const addressDetails = getStringifyAddress();
    return (
      !newUser &&
      !toggleAddDeliveryForm && (
        <div className="tw-px-[16px] tw-pb-[24px] md:tw-border-t md:tw-border-solid md:tw-border-[#EAEAEC] md:tw-pl-[46px] md:tw-pr-[32px] md:tw-pt-[24px] ">
          <UserAddressCard
            onEditClick={() => {
              handleAddDeliveryForm(true);
              setEditId(addressDetails?.address_id || 0);
            }}
            estimatedDeliveryTime={estimatedDeliveryTime.deliveryTime || ''}
            userName={addressDetails?.user_name}
            addressType={addressDetails?.address_type || addressDetails?.addressType}
            addressName={addressDetails?.address_name || addressDetails?.addressName}
            alternatePhone={addressDetails?.alternate_phone}
            address1={addressDetails?.address1}
            address2={addressDetails?.address2}
            city={addressDetails?.city}
            state={addressDetails?.state}
            pincode={addressDetails?.pincode}
          />
        </div>
      )
    );
  }

  function handleAddDeliveryForm(flag) {
    dispatch(setAddDeliveryForm(flag));
  }

  function handleAddressCta() {
    if (userData?.isLogin) {
      newUser ? dispatch(setAddDeliveryForm(true)) : toggleShowDeliveryList();
    } else {
      showAuthModal?.();
    }
  }

  function renderAddressCta() {
    return (
      <AddressCtaText
        toggleAddDeliveryForm={toggleAddDeliveryForm}
        color={storeData?.theme?.colors?.primary_color}
      >
        {!newUser && (IS_DESKTOP ? 'Select from saved addresses' : 'Saved addresses')}
        {newUser && toggleAddDeliveryForm && ''}
        {newUser && !toggleAddDeliveryForm && 'Add address'}
      </AddressCtaText>
    );
  }

  function renderLoggedOutAddressCta() {
    return (
      <AddressCtaText
        toggleAddDeliveryForm={toggleAddDeliveryForm}
        color={storeData?.theme?.colors?.primary_color}
      >
        Add address
      </AddressCtaText>
    );
  }

  function getErrorText() {
    if (cartData?.is_store_unserviceable) {
      return 'Sorry, we are currently unserviceable at this pincode';
    }
    if (cartData?.is_cart_unserviceable) {
      return "Some items in your cart can't be delivered to this address";
    }
  }

  function renderCartNotServicable() {
    return (
      <>
        {(cartData?.is_cart_unserviceable || cartData?.is_store_unserviceable) &&
          !toggleAddDeliveryForm && (
            <CartError
              text={getErrorText()}
              iconWidth={14}
              iconHeight={14}
              mri={8}
              width={deviceWidth < 767 ? '100vw' : '34.48vw'}
              fontSize={12}
              mt={12}
              mb={deviceWidth > 767 ? 16 : 0}
              transXi={'-35px'}
              transX={'-24px'}
              mobileMb={'18px'}
            />
          )}
      </>
    );
  }

  const showError = IS_DESKTOP && showAddressError;

  return (
    <>
      <div className="tw- tw-flex tw-flex-col tw-gap-[12px]" id="cart-address-section">
        {showError && (
          <p className="tw-m-0 tw-mt-[16px] tw-text-sm tw-text-[#F63D60]">
            Please add a delivery address before placing the order
          </p>
        )}
        <AddressFormContainer
          className={`${showError ? '!tw-border-[#F63D60]' : 'md:!tw-mt-[16px]'} `}
          showBorderTop={props.showTopBorderMobile}
        >
          <AddressTabContainer
            isErrorThrown={
              cartData?.is_cart_unservicable || cartData?.is_store_unserviceable
            }
            toggleAddDeliveryForm={toggleAddDeliveryForm}
          >
            <CheckoutInfoTabLeftContainer>
              {IS_DESKTOP && (
                <AddressTabLeftIcon toggleAddDeliveryForm={toggleAddDeliveryForm}>
                  <ShippingTruck />
                </AddressTabLeftIcon>
              )}
              <CheckoutInfoTabMiddleContainer
                cartError={cartData?.is_store_unserviceable}
              >
                <div className="flex tw-text-[16px] tw-font-semibold md:tw-text-[14px]  md:tw-font-medium">
                  Delivery Address
                </div>
                {!IS_DESKTOP && renderCartNotServicable()}
              </CheckoutInfoTabMiddleContainer>
            </CheckoutInfoTabLeftContainer>
            <CheckoutInfoTabRightContainer onClick={handleAddressCta}>
              {!!userData?.isLogin ? renderAddressCta() : renderLoggedOutAddressCta()}
            </CheckoutInfoTabRightContainer>
          </AddressTabContainer>
          {!!userData?.isLogin && renderSelectedAddress()}
          {IS_DESKTOP && renderCartNotServicable()}
          {toggleAddDeliveryForm && (
            <AddDelivery
              toggleAddDeliveryForm={handleAddDeliveryForm}
              edit_id={editId}
              setEditId={handleEditId}
            />
          )}
        </AddressFormContainer>
      </div>
      <DeliveryList
        isVisible={showDeliveryList}
        toggleModal={toggleShowDeliveryList}
        toggleAddDeliveryForm={handleAddDeliveryForm}
        edit_id={editId}
        setEditId={handleEditId}
      />
    </>
  );
}

export default CheckoutInfoTabAddress;
