import { useSelector } from 'react-redux';
import { ShieldIcon, VerifiedIcon } from '../../SVGs';
import { RootState } from 'src/redux/reducers';

const KYC_DONE_FLAG = 3;

const CustomerAssurityBadges = () => {
  const { storeData } = useSelector((state: RootState) => ({
    storeData: state.storeReducer.store,
  }));

  const isKycDone = storeData?.kyc_status === KYC_DONE_FLAG;

  const assurityData = [
    {
      show: true,
      Icon: ShieldIcon,
      boldText: 'Secured',
      normalText: 'Payment',
    },
    {
      show: isKycDone,
      Icon: VerifiedIcon,
      boldText: 'Verified',
      normalText: 'Merchant',
    },
  ];

  return (
    <div className="tw-flex tw-items-center tw-justify-center tw-gap-[20px] tw-text-[14px] tw-opacity-60 md:tw-pt-[4px]">
      {assurityData.map(
        ({ show, Icon, boldText, normalText }, index) =>
          show && (
            <span className="tw-flex tw-gap-[2px]" key={index}>
              <Icon height={16} width={16} />
              <span className="tw-font-bold">{boldText}</span> {normalText}
            </span>
          )
      )}
    </div>
  );
};
export default CustomerAssurityBadges;
