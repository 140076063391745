export function WorkDarkIcon() {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.714286 2.5H2.85714V0.375C2.85714 0.168 3.01714 0 3.21429 0H6.78571C6.98286 0 7.14286 0.168 7.14286 0.375V2.5H9.28571C9.68 2.5 10 2.836 10 3.25V9.25C10 9.664 9.68 10 9.28571 10H0.714286C0.32 10 0 9.664 0 9.25V3.25C0 2.836 0.32 2.5 0.714286 2.5ZM3.57143 2.5H6.42857V0.75H3.57143V2.5Z"
        fill="black"
      />
    </svg>
  );
}
