import { useEffect } from 'react';
import BasicModal from '../BasicModal';
import { getThemeColor } from 'src/utils/getThemeColor';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { setUnavailableQuantityAlertState } from 'src/redux/actions';

const QuntityUnavailableAlert = () => {
  const themeColor = getThemeColor();
  const dispatch = useDispatch();
  const { availableQuantity, onConfirm, onCancel, show } = useSelector(
    (state: RootState) => state.commonReducer.unavailableQuantityAlertState
  );

  useEffect(() => {
    return () => onClose();
  }, []);

  function closeModal() {
    dispatch(
      setUnavailableQuantityAlertState({
        show: false,
        availableQuantity: null,
        onConfirm: null,
        onCancel: null,
      })
    );
  }
  function onClose() {
    onCancel?.();
    show && closeModal();
  }

  function onConfirmClick() {
    onConfirm?.();
    closeModal();
  }

  if (!show) {
    return <></>;
  }

  return (
    <BasicModal
      show={true}
      showCloseIcon={false}
      onClose={onClose}
      heading="Item quantity not available"
      headingClasses="!tw-border-none !tw-pt-[32px] !tw-px-[16px] md:!tw-px-[24px]"
      customClasses="!tw-max-w-[440px] !tw-min-w-[300px]"
    >
      <div className="tw-flex tw-flex-col tw-gap-[24px] tw-px-[16px] tw-pb-[22px] md:tw-px-[24px]">
        <p className="tw-m-0 tw-text-sm tw-text-[#606060]">
          We only have <b>{availableQuantity} quantity</b> available for this item, Do you
          wish to purchase this quantity?
        </p>
        <div className="tw-flex tw-justify-end tw-gap-[16px]">
          <button
            onClick={onClose}
            className="tw-cursor-pointer tw-border tw-border-solid tw-border-[#383838] tw-bg-transparent tw-p-[12px]"
          >
            Cancel
          </button>
          <button
            onClick={onConfirmClick}
            className="tw-cursor-pointer tw-border-none tw-p-[12px] tw-text-white"
            style={{
              backgroundColor: themeColor || '#000000',
            }}
          >
            Update Quantity
          </button>
        </div>
      </div>
    </BasicModal>
  );
};

export default QuntityUnavailableAlert;
