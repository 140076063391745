import CartPromoStrip from "../CartPromoStrip";

export const CartPromosContainer = ({
  promos,
  customClasses = '',
  onDetailClick = null,
  onApplyClick = null,
  isStorePromos = true,
  cartPayAmount = null,
}) => {
  return (
    !!promos?.length && (
      <div
        className={
          'tw-flex tw-flex-col tw-gap-[20px] tw-transition-all md:tw-gap-[16px]' +
          customClasses
        }
      >
        {isStorePromos ? (
          <>
            {promos.map(({ id, code, description, error_text, discount }, index) => (
              <CartPromoStrip
                key={index}
                primaryText={code}
                secondaryText={description}
                promoId={id}
                onDetailClick={onDetailClick}
                onApplyOrRemoveClick={onApplyClick}
                errorText={error_text}
                savingAmount={discount}
              />
            ))}
          </>
        ) : (
          <>
            {promos.map(
              (
                { promo_id, promo_code, offer_callout, description, min_order_price },
                index
              ) => (
                <CartPromoStrip
                  key={index}
                  primaryText={promo_code || offer_callout}
                  secondaryText={description}
                  promoId={promo_id}
                  onApplyOrRemoveClick={onApplyClick}
                  onDetailClick={onDetailClick}
                  isApplyButtonDisabled={min_order_price > cartPayAmount}
                />
              )
            )}
          </>
        )}
      </div>
    )
  );
};
