import { CartBodyRightSection, ItemAmountContainer } from '../../cart.styles';
import { useRef, useState } from 'react';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import CartItemsAndCheckoutSectionHeading from './CartItemsAndCheckoutSectionHeading';
import AllCartItems from './AllCartItems';
import OrderAmountInfo from './OrderAmountInfo';
import CartCheckoutCTA from './CartCheckoutCTA';

export const CartSummery = ({
  showOrderSummary,
  handleOrderSummary,
  referenceToCheckScrollHeight = null,
  summeryClasses = '',
  showDropdown = false,
  allItems,
}) => {
  return (
    <>
      <CartItemsAndCheckoutSectionHeading
        showSummary={showOrderSummary}
        hanldeSummary={handleOrderSummary}
        summeryClasses={summeryClasses}
        showDropdown={showDropdown}
      />
      {showOrderSummary && (
        <ItemAmountContainer
          ref={referenceToCheckScrollHeight}
          showOrderSummary={showOrderSummary}
          showDropdown={showDropdown}
        >
          <AllCartItems
            allItems={allItems}
            referenceToCheckScrollHeight={referenceToCheckScrollHeight}
          />
          <OrderAmountInfo showGrandTotal={showDropdown} />
        </ItemAmountContainer>
      )}
    </>
  );
};

const CartItemsAndCheckout = ({ allItems }) => {
  const [showOrderSummary, setShowOrderSummary] = useState(true);
  const referenceToCheckScrollHeight = useRef();

  function handleOrderSummary() {
    if (!IS_DESKTOP) setShowOrderSummary(!showOrderSummary);
  }

  return (
    <CartBodyRightSection>
      <CartSummery
        showOrderSummary={showOrderSummary}
        handleOrderSummary={handleOrderSummary}
        referenceToCheckScrollHeight={referenceToCheckScrollHeight}
        allItems={allItems}
      />
      <CartCheckoutCTA
        allItems={allItems}
        referenceToCheckScrollHeight={referenceToCheckScrollHeight}
      />
    </CartBodyRightSection>
  );
};

export default CartItemsAndCheckout;
