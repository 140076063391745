import { getItemDeliveryCharges } from '@/redux/actions';
import store from '@/redux/store';
import { getItemQuantityNameId } from './getItemQuantityNameId';
import { getStringifyAddress } from './getStringifyAddress';

export function triggerGetItemDeliveryCharges(
  defaultPayload = {
    pincode: '',
    pay_amount: -1,
    item_id: 0,
    item_name: '',
  }
) {
  const cartData = store.getState().cartReducer;
  const storeData = store.getState().storeReducer.store;
  const addressDetails = getStringifyAddress();

  /** Check if defaultPayload has a pay_amount >= 0, if not, use cartData's pay_amount */
  const selectedPayAmount =
    defaultPayload.pay_amount >= 0 ? defaultPayload.pay_amount : cartData.pay_amount;

  const payload = {
    pincode: defaultPayload?.pincode ? defaultPayload?.pincode : addressDetails?.pincode,
    pay_amount: selectedPayAmount,
    item_details: getItemQuantityNameId(
      defaultPayload?.item_id,
      defaultPayload?.item_name
    ),
    store_id: storeData?.store_id,
  };
  if (payload?.pincode) store.dispatch(getItemDeliveryCharges(payload));
}
