export const PageIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="14.206"
      height="17.758"
      viewBox="0 0 14.206 17.758"
    >
      <path
        id="description_FILL0_wght400_GRAD0_opsz48"
        d="M11.529,17.984h7.147V16.652H11.529Zm0-3.773h7.147V12.879H11.529Zm-2.2,7.547A1.366,1.366,0,0,1,8,20.426V5.332A1.366,1.366,0,0,1,9.332,4h8.013l4.861,4.861V20.426a1.366,1.366,0,0,1-1.332,1.332Zm7.347-12.3V5.332H9.332V20.426H20.874V9.46ZM9.332,5.332v0Z"
        transform="translate(-8 -4)"
      />
    </svg>
  );
};
