import styled from 'styled-components';

export const OrderInstructionTextArea = styled.textarea`
  width: 90%;
  resize: none;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const OrderInstructionInfoTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  margin-top: 16px;
  border: 1px solid #e8e8e8;
  min-height: ${(props) => (props.cartSavedOrderText ? '93' : '0')}px;
  padding-bottom: ${(props) => (props.cartSavedOrderText ? '24' : '0')}px;
  ${(props) => props.isEdit && `padding-bottom : 0`}px;
  @media screen and (max-width: 767px) {
    width: 100vw;
    border-left: none;
    border-right: none;
    border-top: none;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0px;
    margin-top: 0px;
  }
`;

export const OrderInstructionInfoTabAddCta = styled.div`
  color: ${(props) => props.color || '#000000'};
  background-color: ${(props) => props.background || 'transparent'};
  font-weight: 600;
  cursor: pointer;
  font-size: 14px;
`;

export const OrderInstructionInfoTabTypingCta = styled.div`
  display: flex;
  flex-direction: row;
  font-weight: 600;
  margin-right: 16px;
  justify-content: flex-end;
  margin-bottom: 22px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const OrderInstructionInfoTabTypingCancelCta = styled.div`
  display: flex;
  font-weight: 500;
  cursor: pointer;
  margin-right: 20px;
  color: #7f7f7f;
  font-size: 14px;
`;

export const OrderInstructionInfoTabTypingAddCta = styled.div`
  display: flex;
  cursor: pointer;
  color: ${(props) => props.color || '#000000'};
  font-weight: 500;
  font-size: 14px;
`;

export const OrderInstructionInfoTabSavedTextCta = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const OrderInstructionInfoTabEditCta = styled.div`
  display: flex;
  color: ${(props) => props.color || '#000000'};
  font-weight: 500;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const OrderInstructionInfoTabRemoveCta = styled.div`
  display: flex;
  font-weight: 500;
  margin-right: 20px;
  color: #7f7f7f;
  font-size: 14px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
`;

export const OrderInstructionInfoTabSavedText = styled.div`
  display: flex;
  width: 20vw;
  margin-left: 20px;
  font-size: 14px;
  color: #7f7f7f;
`;
