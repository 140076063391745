import { useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { SectionHeader, SuccessMessage } from './Common';
import OfferNotAppliedView from './OfferNotAppliedView';
import OfferAppliedView from './OfferAppliedView';
import SuccesIconWhite from '@/assets/svgExports/SuccessIconWhite';
import { useFeatureLocks } from 'src/hooks/useFeatureLocks';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';

const AllCartPagePromos = () => {
  const { paymentOffersList, cartData } = useSelector((state: RootState) => ({
    paymentOffersList: state.promoReducer.paymentOffersList,
    cartData: state.cartReducer,
  }));

  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [successPopupState, setSuccessPopupState] = useState({
    show: false,
    appliedPromoCode: null,
  });

  const [openDropdown, setOpenDropdown] = useState(false);
  const [unopenedTabText, setUnopenedTabText] = useState('');

  const { couponsFeatureStatus } = useFeatureLocks();
  const isCouponsFeatureLocked = !!couponsFeatureStatus?.isLocked;

  const hasPaymentOffers = !!paymentOffersList?.length;

  const tabsData = [
    {
      text: 'Store Offers',
    },
    {
      text: 'Bank Offers',
    },
  ];

  const storeOfferDataFromCart = cartData?.store_offer;
  const bankOfferDataFromCart = cartData?.payment_offer;

  const isBankOfferApplied = !!bankOfferDataFromCart?.promo_id;
  const isStoreOfferApplied = !!storeOfferDataFromCart?.promo_code;

  const isOfferApplied = isBankOfferApplied || isStoreOfferApplied;

  const getSuccessMessage = (promoCode) => {
    return (
      <div className="tw-flex tw-items-center tw-gap-[8px] tw-text-sm tw-text-white">
        <SuccesIconWhite /> Coupon
        <span className="tw-flex tw-items-center tw-font-bold">{promoCode} </span>
        applied successfully.
      </div>
    );
  };

  return (
    !isCouponsFeatureLocked && (
      <>
        <div className="tw-flex tw-flex-col md:tw-rounded-[8px] md:tw-border md:tw-border-solid md:tw-border-[#EAEAEC]">
          <SectionHeader
            activeTabIndex={activeTabIndex}
            setActiveTabIndex={setActiveTabIndex}
            tabsData={tabsData}
            hasPaymentOffers={hasPaymentOffers}
            isCouponApplied={isOfferApplied}
            UnopenedTabText={unopenedTabText}
            openDropDown={openDropdown}
            handleDropDown={() => setOpenDropdown(!openDropdown)}
          />
          {isOfferApplied ? (
            <OfferAppliedView
              cartData={cartData}
              isBankOfferApplied={isBankOfferApplied}
              bankOfferDataFromCart={bankOfferDataFromCart}
              storeOfferDataFromCart={storeOfferDataFromCart}
            />
          ) : (
            <OfferNotAppliedView
              activeTabIndex={activeTabIndex}
              setSuccessPopupState={setSuccessPopupState}
              showView={IS_DESKTOP || openDropdown}
              setUnOpenedTabText={setUnopenedTabText}
            />
          )}
        </div>
        <SuccessMessage
          show={successPopupState.show}
          onClose={() =>
            setSuccessPopupState({
              ...successPopupState,
              show: false,
            })
          }
          message={getSuccessMessage(successPopupState.appliedPromoCode)}
        />
      </>
    )
  );
};

export default AllCartPagePromos;
