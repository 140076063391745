import styled from 'styled-components';

export const ListHeading = styled.div`
  font-size: 14px;
  color: #6c6c6c;
  letter-spacing: 0.9px;
  @media screen and (max-width: 767px) {
    font-size: 12px;
  }
`;
