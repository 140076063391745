/**
 * Payment Modal
 * Component
 */

import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import debounce from 'lodash/debounce';
import Modal from '../Common/Modal';
import PaymentOptions from '@/components/PaymentOptions';
// import LoaderIcon from '@/components/Common/LoaderIcon';
import {
  fetchOrderByHash,
  fetchPaymentMethods,
  initLazyPayPayment,
  initPayment,
  pushEventsLog,
  savePaymentInfo,
  setPaymentModalOptionsVisiblity,
  setPaymentModalVisiblity,
  toggleLazyPayAuthModal,
  togglePartialPaymentMode,
  updateOrderState,
} from 'src/redux/actions';
import { deviceWidth } from '@/utils/deviceWidth';
import { codMethodRender } from '@/utils/codMethodRender';
import { getDeviceInfo } from '@/utils/deviceInfo';
import { useRouter } from 'next/router';
import { getRoute, PATH } from '@/utils/routes';
import LazyPayAuth from '../LazyPayAuth';
import { _getCODCharges } from '../../components/DesktopPaymentModal';
import {
  MAX_MOBILE_WIDTH,
  PAYMENT_TYPE_CONSTANTS,
  PARTIAL_PAYMENT_OPTION,
} from '@/utils/constants';
import { getEncrypDecryptStr } from '@/utils/encryptionDecryption';
import { useFeatureLocks } from '@/hooks/useFeatureLocks';
import { toastNotifyInfo } from '../Common/Toast';
import TotalAmountSaved from './components/TotalAmountSaved';
import SelectPartialOrFullPayment from './components/SelectPartialPaymentType';
import SelectCashOnDelivery from './components/SelectCashOnDelivery';
import { PaymentOrPlaceOrderCTA } from './components/PaymentOrPlaceOrderCTA';
import { useWidgetDndContextData } from '@/context/WidgetDndContext';
import { GlobalCfeLoader } from '@/components/WidgetMaker/WidgetDnD/GlobalCfeLoader';
import useUnselectablePaymentOptions from 'src/hooks/useUnselectablePaymentOptions';
import { canDisablePlaceOrder } from 'src/utils/canDisablePlaceOrder';

const PaymentModal = () => {
  const {
    widgetContextState: { globalStyle },
  } = useWidgetDndContextData();
  const dispatch = useDispatch();
  const router = useRouter();

  const [state, setState] = useState({
    payOnDelivery: false,
    selectedPaymentData: null,
  });

  const { partialPaymentFeatureStatus } = useFeatureLocks();
  const partialPaymentFeatureVisible = partialPaymentFeatureStatus?.isVisible;
  const [codCharge, setCodCharge] = useState(0);

  const [selectedPartialPaymentMode, setSelectedPartialPaymentMode] = useState(
    PARTIAL_PAYMENT_OPTION.FULL_PAYMENT
  );

  const {
    paymentModalVisibility,
    orderDetails,
    storeInfo,
    loader,
    cart,
    otherMethods,
    paymentOptionsWithTxnCharge,
    userData,
    paymentModalOptionsVisibility,
  } = useSelector((state) => ({
    paymentModalVisibility: state.commonReducer.paymentModalVisibility,
    orderDetails: state.orderReducer.orderDetails,
    storeInfo: state.storeReducer.store,
    loader: state.commonReducer.loader,
    cart: state.cartReducer,
    otherMethods: state.paymentReducer.otherMethods,
    paymentOptionsWithTxnCharge: state.paymentReducer.paymentOptionsWithTxnCharge,
    userData: state.userReducer.data,
    partial_payment_amount: state.paymentReducer.partial_payment_amount,
    paymentModalOptionsVisibility: state.commonReducer.paymentModalOptionsVisibility,
  }));

  const { finalUnselectablePaymentOptions } = useUnselectablePaymentOptions();

  const isPartialPaymentEnabled =
    partialPaymentFeatureVisible &&
    storeInfo?.services?.partial_payment_flag &&
    orderDetails.prepaid_flag > 0;

  const isChildOrder =
    orderDetails.parent_order_id !== 0 && orderDetails.partial_payment_type > 0;

  const showPartialPayment = isPartialPaymentEnabled && !isChildOrder;

  useEffect(() => {
    setCodCharge(_getCODCharges(orderDetails, storeInfo?.services));
    dispatch(setPaymentModalOptionsVisiblity(true));
    if (paymentModalVisibility) {
      const paymentMethod = cart.payment_offer?.payment_method;
      if (paymentMethod && paymentMethod !== PAYMENT_TYPE_CONSTANTS.LAZYPAY) {
        const isCardTypeMethod =
          paymentMethod === PAYMENT_TYPE_CONSTANTS.DEBIT_CARD ||
          paymentMethod === PAYMENT_TYPE_CONSTANTS.CREDIT_CARD;
        const finalPaymentMethod = isCardTypeMethod
          ? PAYMENT_TYPE_CONSTANTS.CARD
          : paymentMethod;
        debouncedSubmitPayment(finalPaymentMethod, true);
      } else {
        document.getElementsByTagName('body')[0].style.overflowY = 'hidden';
      }
    } else {
      document.getElementsByTagName('body')[0].style.overflowY = 'scroll';
    }
    clearSelectedPaymentMode();
  }, [paymentModalVisibility]);

  useEffect(() => {
    if (paymentModalVisibility && !cart?.payment_offer?.payment_method) {
      dispatch(
        pushEventsLog({
          event_name: 'Cx_Payment_Init',
          data: {
            store_id: storeInfo?.store_id,
            domain: storeInfo?.store_info?.domain,
            device: deviceWidth <= MAX_MOBILE_WIDTH ? 'Mobile' : 'Desktop',
            cx_number: userData?.phone,
          },
        })
      );
    }
  }, [paymentModalVisibility, cart?.payment_offer?.payment_method]);

  const submitPayment = (
    method,
    isPaymentPromoPresent = false,
    selectedPaymentMethod = {},
    orderHash = orderDetails?.order_hash
  ) => {
    if (loader) {
      toastNotifyInfo('Payment initiation in progress.');
      return;
    }
    /**
     * if payment method is emi
     * reroute to /emi-payment page for emi payment
     * through card
     */
    if (method === PAYMENT_TYPE_CONSTANTS.EMI) {
      router.push({
        pathname: getRoute(PATH.EMI_PAYMENT, storeInfo?.store_info?.domain),
        query: {
          emiPrice: orderDetails?.buyer_pay_value,
        },
      });
    } else {
      const payload = {
        orderID: orderHash,
        paymentMethod: method,
        merchantID: orderDetails?.merchant_id,
        deviceType: getDeviceInfo().OSName,
        browserType: getDeviceInfo().BrowserName,
        userAgent: getDeviceInfo().userAgent,
        source: 'longtail',
        merchantName: `Digital Showroom - ${storeInfo?.store_info?.name}`,
        phone: userData?.phone,
      };
      dispatch(savePaymentInfo(payload));
      if (isPaymentPromoPresent) {
        payload['promoCode'] = cart?.payment_offer?.promo_code;
      }
      if (method === PAYMENT_TYPE_CONSTANTS.CARD && !isPaymentPromoPresent) {
        router.push(getRoute(PATH.cardPayment(orderHash), storeInfo?.store_info?.domain));
        return;
      }

      if (method === PAYMENT_TYPE_CONSTANTS.CARD && isPaymentPromoPresent) {
        const cardDetails = JSON.parse(
          getEncrypDecryptStr(JSON.parse(localStorage?.getItem('dscc')), 'decrypt')
        );
        payload['cNum'] = cardDetails.cNum;
        payload['cCvv'] = cardDetails.cCvv;
        payload['cName'] = cardDetails.cName;
        payload['cExpMonth'] = cardDetails.cExpMonth;
        payload['cExpYear'] = cardDetails.cExpYear;
      }

      if (method === PAYMENT_TYPE_CONSTANTS.LAZYPAY) {
        payload.phone = orderDetails.phone;
        payload.amount = storeInfo?.services?.mdr_flag
          ? selectedPaymentMethod?.pay_amount
          : orderDetails.amount;
        payload.store_id = orderDetails.store_id;
        dispatch(initLazyPayPayment(payload));
      } else {
        dispatch(initPayment(payload, router));
      }
    }
    localStorage?.setItem('orderID', orderHash);
  };

  const onSelectPaymentMode = (
    method,
    isPaymentPromoPresent = false,
    selectedPaymentMethod = {},
    orderHash = orderDetails?.order_hash
  ) => {
    state.payOnDelivery && togglePayOnDeliveryCheckbox();
    setState((state) => ({
      ...state,
      selectedPaymentData: {
        method,
        isPaymentPromoPresent,
        selectedPaymentMethod,
        orderHash,
      },
    }));
  };

  const debouncedSubmitPayment = debounce(submitPayment, 1500, {
    leading: true,
    trailing: false,
  });

  const onClose = () => {
    setSelectedPartialPaymentMode(PARTIAL_PAYMENT_OPTION.FULL_PAYMENT);
    dispatch(setPaymentModalVisiblity(false));
    dispatch(setPaymentModalOptionsVisiblity(true));
  };

  const clearSelectedPaymentMode = () => {
    state.selectedPaymentData &&
      setState((state) => ({
        ...state,
        selectedPaymentData: null,
      }));
  };

  const togglePayOnDeliveryCheckbox = () => {
    setState((state) => ({ ...state, payOnDelivery: !state.payOnDelivery }));
    clearSelectedPaymentMode();
  };

  const onPlaceOrderBtnClick = () => {
    if (canDisablePlaceOrder()) {
      return;
    }
    dispatch(updateOrderState(orderDetails?.order_hash, router));
  };

  const onCloseLazyPayAuthModal = () => {
    dispatch(toggleLazyPayAuthModal({ show: false }));
  };

  const handleInputRadioClick = (type) => {
    // if user is clicking same input radio again n again, don't allow any network call.
    if (type === selectedPartialPaymentMode) return;
    dispatch(setPaymentModalOptionsVisiblity(false));
    const payload = {
      store_id: storeInfo?.store_id,
      order_id: orderDetails?.order_id,
      part_payment_option: type,
      pay_amount: orderDetails?.pay_amount,
    };
    dispatch(
      togglePartialPaymentMode(payload, () => {
        dispatch(
          fetchPaymentMethods({
            store_id: storeInfo?.store_id,
            hash: orderDetails?.order_hash,
          })
        );
        dispatch(
          fetchOrderByHash({
            order_hash: orderDetails.order_hash,
            isPrepaid: 1,
          })
        );
        dispatch(setPaymentModalOptionsVisiblity(true));
        setSelectedPartialPaymentMode(type);
      })
    );
  };

  const renderUnselectablePaymentOption = () => {
    return (
      <>
        {finalUnselectablePaymentOptions.map((paymentOption) => {
          switch (paymentOption) {
            case PAYMENT_TYPE_CONSTANTS.COD: {
              return (
                <SelectCashOnDelivery
                  isSelectedCOD={state.payOnDelivery}
                  toggleCOD={togglePayOnDeliveryCheckbox}
                  codCharge={codCharge}
                  ctaType="cart_confirm_cta"
                  unselectablePaymentOptions={finalUnselectablePaymentOptions}
                />
              );
            }
            default:
              return <></>;
          }
        })}
      </>
    );
  };

  return (
    paymentModalVisibility &&
    !cart?.payment_offer?.payment_method && (
      <>
        <Modal
          visible={paymentModalVisibility}
          className="bottom-modal cartValidationErrorMsgModal"
          animation={'slideUp'}
          onClose={onClose}
        >
          <div
            className={
              state.selectedPaymentData || state.payOnDelivery ? 'tw-pb-[5.0625rem]' : ''
            }
          >
            <span className="closeicon" onClick={onClose}>
              <img loading="lazy" src="/assets/images/cancel.png" alt="cancel" />
            </span>

            {/* If either partial payment is not enabled by Mx or the order is of type child order, then don't render partialPayment component */}
            {showPartialPayment ? (
              <SelectPartialOrFullPayment
                selectedType={selectedPartialPaymentMode}
                setSelectedType={handleInputRadioClick}
              />
            ) : (
              <>
                <TotalAmountSaved amount={orderDetails?.amount_saved} />
                <div className="f4 fw6 pv3">Pay ₹{orderDetails?.buyer_pay_value}</div>
                <div className="f6 fw6 pv3 o-50">Choose a Payment Method</div>
              </>
            )}
            {!paymentModalOptionsVisibility ? (
              <div className="flex justify-center items-center bg-white">
                <GlobalCfeLoader
                  type={globalStyle?.appLoader?.loaderId || 'ellipsis'}
                  color={globalStyle?.appLoader?.color}
                />
                {/* <LoaderIcon /> */}
              </div>
            ) : (
              <>
                <PaymentOptions onSelect={onSelectPaymentMode} />
                {codMethodRender(otherMethods, paymentOptionsWithTxnCharge, storeInfo)
                  ?.length > 0 && (
                  <SelectCashOnDelivery
                    isSelectedCOD={state.payOnDelivery}
                    toggleCOD={togglePayOnDeliveryCheckbox}
                    codCharge={codCharge}
                    ctaType="cart_confirm_cta"
                  />
                )}
                {renderUnselectablePaymentOption()}
              </>
            )}
          </div>
        </Modal>
        <PaymentOrPlaceOrderCTA
          state={state}
          onProceedToPay={debouncedSubmitPayment}
          onPlaceOrder={onPlaceOrderBtnClick}
        />
        <LazyPayAuth onClose={onCloseLazyPayAuthModal} />
      </>
    )
  );
};

export default PaymentModal;
