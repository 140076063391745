export function HomeDarkIcon() {
  return (
    <svg
      width="10"
      height="11"
      viewBox="0 0 10 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.73138 4.84952C9.73115 4.84929 9.73092 4.84906 9.73069 4.84883L5.65107 0.769775C5.47718 0.595825 5.24598 0.5 5.00006 0.5C4.75414 0.5 4.52295 0.595749 4.34898 0.769699L0.271492 4.84669C0.270118 4.84807 0.268745 4.84952 0.267372 4.85089C-0.0897206 5.21001 -0.0891102 5.79266 0.269126 6.15086C0.432794 6.31459 0.648956 6.40942 0.880074 6.41934C0.889459 6.42026 0.898921 6.42072 0.908459 6.42072H1.07106V9.42265C1.07106 10.0167 1.55443 10.5 2.14867 10.5H3.74475C3.90651 10.5 4.03774 10.3689 4.03774 10.207V7.85352C4.03774 7.58244 4.25826 7.36195 4.52936 7.36195H5.47077C5.74187 7.36195 5.96238 7.58244 5.96238 7.85352V10.207C5.96238 10.3689 6.09354 10.5 6.25538 10.5H7.85146C8.44569 10.5 8.92907 10.0167 8.92907 9.42265V6.42072H9.07984C9.32568 6.42072 9.55688 6.32497 9.73092 6.15102C10.0895 5.79221 10.0897 5.20856 9.73138 4.84952Z"
        fill="black"
      />
    </svg>
  );
}
