import useOnScrollRemoveShadow from 'src/hooks/useOnScrollRemoveShadow';
import { BottomContainer, CTAbutton } from '../../cart.styles';
import { useSelector } from 'react-redux';
import { RootState } from 'src/redux/reducers';
import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import CartError from 'src/components/CartError/CartError';
import getCustomCtaText from 'src/utils/getCustomCtaText';
import { ORDER_TYPE, PREPAID_POSTPAID_METHODS } from 'src/utils/constants';
import { isDeliveryPincodeBased } from 'src/utils/isDeliveryPincodeBased';
import { getThemeColor } from 'src/utils/getThemeColor';
import { isStoreServiceBased } from 'src/utils/isStoreServiceBased';
import CustomerAssurityBadges from '../CustomerAssurityBadges';
import GrandTotal from './GrandTotal';
import { useContext } from 'react';
import { CartContext } from '@/context/CartContext';
import { canDisablePlaceOrder } from 'src/utils/canDisablePlaceOrder';

const PLACE_ORDER_BUTTON_OPACITY = '20';

const CartCheckoutCTA = ({ allItems, referenceToCheckScrollHeight }) => {
  const showScrollDownShadow = useOnScrollRemoveShadow(referenceToCheckScrollHeight);
  const themeColor = getThemeColor();

  const { cart, storeInfo, prepaidLimitDisablePayNow, userData, toggleAddDeliveryForm } =
    useSelector((state: RootState) => ({
      cart: state.cartReducer,
      storeInfo: state.storeReducer.store,
      prepaidLimitDisablePayNow: state.commonReducer.prepaidLimitDisablePayNow,
      userData: state.userReducer,
      toggleAddDeliveryForm: state.commonReducer.toggleAddDeliveryForm,
    }));

  const { onCartPlaceOrderBtnHandle } = useContext(CartContext);

  function getCtalabel() {
    if (storeInfo?.services?.payment_method === PREPAID_POSTPAID_METHODS.PREPAID)
      return 'Pay Now';
    return getCustomCtaText('cart_cta');
  }

  function getIsCtaDisabled() {
    switch (true) {
      case cart?.is_order_blocked: // true if orders or prepaid limit is reached for store.
      case prepaidLimitDisablePayNow:
      case isDeliveryPincodeBased() && cart?.is_cart_unserviceable:
      case userData?.isLogin &&
        cart?.order_type === ORDER_TYPE.DELIVERY &&
        !cart.delivery_info?.address1:
      case cart?.order_type === ORDER_TYPE.DELIVERY && toggleAddDeliveryForm:
        return true;
      default:
        return false;
    }
  }

  function getPlaceOrderCtaColor() {
    if (getIsCtaDisabled()) {
      return themeColor + PLACE_ORDER_BUTTON_OPACITY;
    } else {
      return themeColor;
    }
  }

  function renderTextBelowPlaceOrderCta() {
    const invalidAddressText =
      userData?.isLogin &&
      cart?.order_type === ORDER_TYPE.DELIVERY &&
      !cart.delivery_info?.address1
        ? 'Add valid address and phone number to proceed'
        : '';
    if (getIsCtaDisabled() && !!invalidAddressText) {
      return <div className="f12px mt12px tw-text-red-600">{invalidAddressText}</div>;
    }

    const paymentInfoText = storeInfo?.services?.partial_payment_flag
      ? 'You can choose partial payment in the next step'
      : isStoreServiceBased()
        ? 'You can make payment once you avail the service'
        : null;

    return (
      storeInfo?.services?.payment_method !== PREPAID_POSTPAID_METHODS.PREPAID &&
      !!paymentInfoText && (
        <div className="tw-text-[12px]">
          <div className="tc">{paymentInfoText}</div>
        </div>
      )
    );
  }

  return (
    allItems?.length !== 0 && (
      <BottomContainer
        storeUnserviceable={cart.is_store_unserviceable}
        showScrollDownShadow={showScrollDownShadow}
      >
        <GrandTotal cart={cart} />
        <div className="tw-flex tw-w-full tw-flex-col tw-gap-[12px]">
          {!cart.is_store_unserviceable ? (
            <>
              <CTAbutton
                fluid={true}
                roundness={storeInfo?.theme?.roundness}
                backgroundColor={getPlaceOrderCtaColor()}
                fontColor="#ffffff"
                fontSize={IS_DESKTOP ? 14 : 16}
                fontWeight={700}
                textTransform="capitalize"
                label={getCtalabel()}
                variant="solid"
                disabled={getIsCtaDisabled()}
                borderWidth="0px"
                onClick={() => {
                  if (canDisablePlaceOrder()) {
                    return;
                  }
                  onCartPlaceOrderBtnHandle?.();
                }}
                className="tw-py-[18px] md:tw-py-[16px]"
              />
              {renderTextBelowPlaceOrderCta()}
            </>
          ) : (
            <CartError
              text="Sorry, we are currently unserviceable at this pincode"
              iconWidth={14}
              iconHeight={14}
              fontSize={!IS_DESKTOP ? 12 : 14}
              customClasses="tw-justify-center"
            />
          )}
        </div>
        <CustomerAssurityBadges />
      </BottomContainer>
    )
  );
};

export default CartCheckoutCTA;
