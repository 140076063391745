import ReadMoreReadLess from '@/components/ProductCustomizationPopUp/components/ReadMoreReadLess';

const ItemPersonalizationDetails = ({
  storeData,
  personalizationsData,
  handleEditClick,
}) => {
  if (!personalizationsData?.length) {
    return null;
  }

  return (
    <div className="tw-mt-[16px] tw-flex tw-flex-col tw-gap-3  xs:tw-w-[90vw] md:tw-min-w-[100%] md:tw-max-w-[20vw]">
      {personalizationsData?.map?.((data, idx) => (
        <div key={idx}>
          <div className="tw-flex tw-items-center">
            <span className="tw-flex tw-gap-[0.5rem]">
              <span className="tw-text-[12px] tw-font-normal tw-text-[#242121] md:tw-text-[0.875rem]">
                {' '}
                Personalisation details{' '}
              </span>{' '}
              <span className="tw-text-[12px] tw-font-normal tw-text-[#868686] md:tw-text-[0.875rem]">
                (Qty: {data?.quantity})
              </span>
            </span>
            <span
              className="tw-ml-auto tw-cursor-pointer tw-text-[12px] tw-font-semibold tw-leading-5 tw-text-[#1F1F1F] md:tw-text-[0.875rem]"
              style={{ color: storeData?.theme?.colors?.primary_color || '#1F1F1F' }}
              onClick={() => handleEditClick(data, idx)}
            >
              Edit
            </span>
          </div>
          <div className="tw-mt-[0.75rem] tw-flex tw-flex-col tw-gap-[0.5rem]">
            <div className="tw-flex tw-flex-col tw-gap-[16px]">
              {!!data?.images?.length && (
                <div className="tw-flex tw-flex-col tw-gap-[6px]">
                  <span className="tw-text-[0.75rem] tw-font-normal tw-text-[#4E4E4E]">
                    Image
                  </span>
                  <div className="tw-flex tw-flex-wrap tw-gap-[0.625rem]">
                    {data?.images?.map?.((imgUrl, idx) => (
                      <img
                        src={imgUrl}
                        key={idx}
                        alt={`${imgUrl}-image`}
                        className="tw-rounded"
                        width={'40px'}
                        height={'40px'}
                        style={{ border: '1px solid #eaeaec' }}
                      />
                    ))}
                  </div>
                </div>
              )}
              {!!data?.texts?.length && (
                <div className="tw-flex tw-flex-col tw-gap-[2px]">
                  <span className="tw-text-[0.75rem] tw-font-normal tw-text-[#4E4E4E]">
                    Text
                  </span>
                  <ReadMoreReadLess
                    text={data?.texts?.[0] || ''}
                    color="#999"
                    maxChar={80}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default ItemPersonalizationDetails;
