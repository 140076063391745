export function ZeroCart(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props.width || 226.768}
      height={props.height || 184.32}
      viewBox="0 0 226.768 184.32"
      {...props}
    >
      <defs>
        <style>
          {
            '.prefix__b{fill:#bfbfbf}.prefix__c{fill:#b1b1b1}.prefix__d{fill:#28384f}.prefix__e{fill:#fff}'
          }
        </style>
      </defs>
      <g transform="translate(8679.17 -11063)">
        <path
          d="M-8470.978 11105.794h-85.675a5.952 5.952 0 110-11.9h12.574a15.445 15.445 0 0015.445-15.445 15.453 15.453 0 00-15.445-15.449H-8649.3a15.453 15.453 0 00-15.445 15.445 15.445 15.445 0 0015.445 15.446h44.439a5.952 5.952 0 110 11.9h-12.087a18.576 18.576 0 00-18.576 18.576 18.576 18.576 0 0018.576 18.576h78.258a5.946 5.946 0 01.231 11.889h-123.9a16.816 16.816 0 00-16.811 16.82 16.816 16.816 0 0016.816 16.809h36.691a5.952 5.952 0 01.88 11.831h-5.93a16.814 16.814 0 100 33.629h117.85a16.814 16.814 0 100-33.629h-10.244a5.952 5.952 0 01.88-11.831h36.59a16.809 16.809 0 0016.809-16.809 16.816 16.816 0 00-16.809-16.816h-5.05a5.946 5.946 0 01.231-11.889h19.478a18.576 18.576 0 0018.578-18.577 18.576 18.576 0 00-18.578-18.576zm-93.5 88.618a5.944 5.944 0 01-5.05 5.88h-8.794a5.952 5.952 0 01.88-11.831h7.012a5.952 5.952 0 015.95 5.951z"
          fill="#f4f4f4"
        />
        <rect
          className="prefix__b"
          width={123.361}
          height={10.821}
          rx={5.411}
          transform="translate(-8627.467 11236.499)"
        />
        <rect
          className="prefix__b"
          width={23.929}
          height={10.821}
          rx={5.411}
          transform="translate(-8659.626 11236.499)"
        />
        <path
          className="prefix__c"
          d="M-8493.009 11247.32h.39a5.411 5.411 0 01-5.411-5.411 5.411 5.411 0 015.411-5.411h-.39a5.4 5.4 0 015.4 5.411 5.4 5.4 0 01-5.4 5.411z"
        />
        <g transform="translate(-8657.581 11112.776)">
          <path
            className="prefix__c"
            d="M142.779 88.601h-93.68l-10.91-74.1h118.268zm-89.1-5.288h84.7l11.72-63.488H44.314z"
          />
          <path
            className="prefix__c"
            d="M44.963 36.323h104.605v2.886H44.963zM49.298 64.062h95.933v2.886H49.298z"
          />
          <path
            className="prefix__c"
            d="M95.821 17.147h2.886v68.808h-2.886zM62.018 17.293l2.87-.298L72.036 85.8l-2.87.298zM121.178 85.778l8.458-68.843 2.866.352-8.458 68.843zM39.407 21.571L26.766 9.011h-16.72v-9h20.42l15.283 15.184z"
          />
          <path
            className="prefix__c"
            d="M136.697 113.521H51.044a17.025 17.025 0 110-34.051h.354v9h-.346a8.029 8.029 0 100 16.051h85.646z"
          />
          <circle
            className="prefix__d"
            cx={16.01}
            cy={16.01}
            r={16.01}
            transform="rotate(-80.8 133.624 -5.902)"
          />
          <circle
            className="prefix__e"
            cx={8.029}
            cy={8.029}
            r={8.029}
            transform="rotate(-89 126.043 -4.47)"
          />
          <circle
            className="prefix__d"
            cx={15.965}
            cy={15.965}
            r={15.965}
            transform="rotate(-80.73 90.744 43.839)"
          />
          <circle
            className="prefix__e"
            cx={8.029}
            cy={8.029}
            r={8.029}
            transform="rotate(-80.75 91.438 35.213)"
          />
          <path
            className="prefix__c"
            d="M20.73 9.01H4.505a4.505 4.505 0 110-9.01h16.232a4.5 4.5 0 010 9z"
          />
        </g>
      </g>
    </svg>
  );
}
