import { IS_DESKTOP } from 'src/utils/checkClientDeviceType';
import {
  StripCTA,
  StripContainer,
  StripIconInfoWrapper,
  StripOfferIcon,
} from '../Common';

const CartPromoStrip = ({
  promoId = null,
  primaryText = '',
  secondaryText = '',
  bgColor = '#ffffff',
  showHoverEffect = true,
  errorText = '',
  savingAmount = 0,
  onDetailClick = null,
  onApplyOrRemoveClick = null,
  isApplyButtonDisabled = false,
  isOfferApplied = false,
  primaryButtonText = '',
  primaryButtonClasses = '',
  secondaryButtonText = '',
  secondaryButtonClasses = '',
}) => {
  return (
    <StripContainer
      showHoverEffect={showHoverEffect}
      bgColor={bgColor}
      customClasses={`!tw-flex-row tw-justify-between tw-items-start tw-border-none ${IS_DESKTOP ? 'tw-px-0' : ''}`}
    >
      <StripIconInfoWrapper>
        <StripOfferIcon iconSize={IS_DESKTOP ? 14 : 12} customClasses="tw-pt-[2px]" />
        <div className="tw-flex tw-flex-col tw-gap-[2px]">
          <p
            className={`tw-m-0 tw-flex tw-gap-[8px] tw-text-[14px]/[20px] tw-font-medium`}
          >
            {primaryText}{' '}
            {!!savingAmount && (
              <span className="tw-text-[12px]/[20px] tw-font-semibold tw-text-[#0D9738] md:tw-text-[14px]/[20px]">
                {isOfferApplied ? 'Saved' : 'Save'} ₹{savingAmount}
              </span>
            )}
          </p>
          <div className="tw-flex tw-flex-col tw-gap-[4px]">
            <p
              className={`tw-m-0 tw-text-[12px]/[20px] tw-font-normal tw-text-[#646464]`}
            >
              {secondaryText}
            </p>
            {errorText ? (
              <p className={`tw-m-0 tw-text-[13px]/[20px] tw-text-[#F63D60]`}>
                {errorText}
              </p>
            ) : null}
            {!!onDetailClick && (
              <StripCTA
                ctaTextOrIcon={secondaryButtonText || 'Details'}
                onClick={() => onDetailClick(promoId)}
                customClasses={`!tw-text-[13px]/[20px] !tw-ml-0 $ ${secondaryButtonClasses}`}
              />
            )}
          </div>
        </div>
      </StripIconInfoWrapper>
      <StripCTA
        ctaTextOrIcon={primaryButtonText || 'Apply'}
        onClick={() =>
          isOfferApplied ? onApplyOrRemoveClick() : onApplyOrRemoveClick(promoId)
        }
        customClasses={`tw-pr-[17px] ${primaryButtonClasses}`}
        isDisabled={!!errorText || isApplyButtonDisabled}
      />
    </StripContainer>
  );
};

export default CartPromoStrip;
